<template>
  <div class="empty">
    <img class="icon" src="~@ass/img/1.4.1/img_ksj.svg" alt="" />
    <div class="text">该模块内容为空~</div>
  </div>
</template>
<script>
export default {
  name: 'empty',
}
</script>
<style lang="scss" scoped>
.empty {
  padding: 60px 0;
  background: #fff;
  .icon {
    height: 88px;
    width: 260px;
    display: block;
    margin: 0 auto;
  }
  .text {
    font-size: 18px;
    color: #999999;
    margin-top: 20px;
    text-align: center;
  }
}
</style>
