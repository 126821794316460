<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-06-27 15:38:45
 * @LastEditTime: 2022-09-08 15:29:46
 * @LastEditors: cyy
 * @Description: 图文
 * @FilePath: \teacherdingding.com\src\views\NetSchollAdmin\pcSchoolDecoration\centerComponents\graphic.vue
-->
<template>
  <div class="course_model">
    <div class="top">
      <div class="top_font1">{{ value.name }}</div>
      <div class="top_font2" v-if="value.is_show_more == 1">
        <a href="javaScript:;">查看全部</a>
        <i class="el-icon-d-arrow-right"></i>
      </div>
    </div>
    <ul class="course-list">
      <li v-for="item in value.list" :key="item.graphic_id" class="item">
        <a class="href" href="javaScript:;">
          <div class="cover">
            <img class="img" :src="item.cover_img" />
          </div>
          <div class="content">
            <div class="title">
              {{ item.name }}
            </div>
            <!-- <div class="detail">
              <span class="number">{{ item.order_num }}次学习</span> 
              有效期：
              <span v-if="memberHeader.validity_type == 1">永久有效</span>
              <span v-else-if="memberHeader.validity_type == 2">
                购买后{{ memberHeader.validity }}天有效
              </span>
              <span v-else>{{ memberHeader.validity | formatTimeStamp }}</span>
            </div> -->
            <div class="price">
              <div class="t1" v-if="item.price_type == 1">
                ￥{{ item.price }}
              </div>
              <div class="t1" v-if="item.price_type == 2">免费</div>
              <div class="t2" v-if="item.cost_price > 0">
                {{ item.cost_price }}
              </div>

              <div class="t1" v-if="item.price_type == 3">
                密码：{{ item.fee_pwd }}
              </div>
            </div>
          </div>
        </a>
      </li>
      <li
        v-for="item in 4 - (value.list.length % 4)"
        :key="item + 'a'"
        class="item"
      >
        <a class="href block" href="javaScript:;"></a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'graphic',

  props: {
    value: Object,
  },

  components: {},
}
</script>
<style lang="scss" scoped>
@import './courseItem.scss';
</style>
