<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-05-13 15:51:30
 * @LastEditTime: 2022-05-20 17:07:57
 * @LastEditors: cyy
 * @Description: 选择链接（pc网校装修）
 * @FilePath: \teacher.dingdingkaike.com\src\components\selectLink.vue
-->
<template>
  <div id="selectLink">
    <el-select
      :value="value.default_type"
      placeholder="请选择"
      size="small"
      style="width: 140px; margin-right: 14px"
    >
      <el-option
        @click.native="optionChange(item.value, index)"
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
    <!-- 选择课程分类 -->
    <selectcourse-type
      @typeselectval="typeselectval"
      v-if="selectcourseTypeStatus"
      :dialogstatus.sync="selectcourseTypeStatus"
      @close="kechengtype"
      title="选择分类（单选）"
    ></selectcourse-type>

    <!-- 选择商品 -->
    <networkschool-select
      @selectChangenetworkschool="closeGoodsConfirm"
      @singleselectcontentval="singleselectcontentval"
      title="选择商品"
      v-if="networkschoolSelectStatus"
      :dialogstatus.sync="networkschoolSelectStatus"
      :template="13"
    ></networkschool-select>

    <!-- 自定义链接 -->
    <el-dialog
      title="自定义链接"
      :visible.sync="customlink"
      width="422px"
      :close-on-click-modal="false"
      @close="customlinkCancel"
      :append-to-body="true"
      :destroy-on-close="true"
      v-if="customlink"
    >
      <el-input placeholder="请输入自定义链接" v-model="link"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="customlinkCancel">取 消</el-button>
        <el-button type="primary" @click="surecustomlink">
          确 定
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择微页面 -->
    <templatelist-select
      v-if="status"
      @selectObj="selectObj"
      :dialogstatus.sync="status"
      @cancelSelect="cancelSelect"
    ></templatelist-select>
  </div>
</template>

<script>
import templatelistSelect from '@cm/templatelistSelect'
import networkschoolSelect from '@cm/base/networktypeSelect/networkschoolSelect'
// //选择课程分类
import selectcourseType from '@cm/base/selectcourseType'
export default {
  name: 'selectLink',

  components: {
    selectcourseType,
    networkschoolSelect,
    templatelistSelect,
  },

  props: {
    index: [String, Number],
    value: [Object],
    // 区别是否需要首页
    id: [String, Number],
    fatherIndex: {
      type: Array,
      default: () => [],
    },
  },

  /**
   * 这里不能用监听事件，没有用，要使用 optionChange 方法来变更数据
   */

  // watch: {
  //   //   copylist(val) {
  //   //     console.log('watchcopylist', val)
  //   //   },

  //   index: {
  //     handler(val) {
  //       console.log(val)
  //     },
  //     immediate: true,
  //   },
  // },

  data() {
    return {
      list: [],
      status: false,
      link: '',
      newlist: {
        id: Math.random(),
        name: '',
        commodity_type: '',
        set_id: '',
        default_type: '',
        link: '',
        url_name: '',
      },
      // 自定义链接
      customlink: false,
      // 选择课程等弹窗
      networkschoolSelectStatus: false,
      // 总按钮 '取消'时使用
      copylist2: {},
      // 深拷贝一份list,在更改链接时使用
      copylist: {},
      selectcourseTypeStatus: false,
      options: this.id
        ? [
            {
              value: '2',
              label: '课程分类',
            },
            {
              value: '3',
              label: '商品 ',
            },
            {
              value: '4',
              label: '电脑网校微页面',
            },
            {
              value: '5',
              label: '自定义链接',
            },
          ]
        : [
            {
              value: '1',
              label: '首页 ',
            },
            {
              value: '2',
              label: '课程分类',
            },
            {
              value: '3',
              label: '商品 ',
            },
            {
              value: '4',
              label: '电脑网校微页面',
            },
            {
              value: '5',
              label: '自定义链接',
            },
          ],
    }
  },
  methods: {
    clearfn(index) {
      this.list = _.assign({}, this.list, {
        default_type: '',
        set_id: '',
        link: '',
        url_name: '',
      })

      this.$emit('compelet', {
        val: this.list,
        index: index,
        fatherIndex: this.fatherIndex,
      })
    },

    submit() {
      this.$emit('compelet', {
        val: this.list,
        index: this.index,
        fatherIndex: this.fatherIndex,
      })
    },

    // 微页面取消选择
    cancelSelect() {
      const obj = _.merge({}, this.copylist)
      this.list = obj
      this.submit()
      this.status = false
      this.reset()
      this.$forceUpdate()
    },

    // 微页面的选择
    selectObj(val) {
      this.list = _.assign({}, this.list, {
        default_type: this.selectType,
        set_id: val.pc_t_home_id,
        link: '',
        url_name: val.name,
      })
      this.copylist = this.list
      this.submit()
    },

    // 获取课程类型
    getcourseType(val) {
      let num = 0
      if ('course_id' in val) {
        num = 1
      } else if ('open_class_id' in val) {
        num = 2
      } else if ('ed_id' in val) {
        num = 3
      } else if ('live_goods_id' in val) {
        num = 4
      }
      return num
    },

    // 课程选择的数据
    singleselectcontentval(val) {
      this.list = _.assign({}, this.list, {
        default_type: this.selectType,
        set_id:
          val.course_id || val.open_class_id || val.ed_id || val.live_goods_id,
        url_name: val.name,
        commodity_type: this.getcourseType(val),
        link: '',
      })
      this.copylist = this.list
      this.submit()
    },

    // 课程选择的数据关闭弹窗
    closeGoodsConfirm() {
      const obj = _.merge({}, this.copylist)
      this.list = obj
      this.submit()
      this.networkschoolSelectStatus = false
    },

    // // 选择下拉框
    optionChange(event, index) {
      this.list = _.merge({}, this.value)
      this.copylist = _.merge({}, this.list)
      this.copylist2 = _.merge({}, this.list)
      this.selectType = event
      this.selectIndex = index
      switch (Number(event)) {
        case 1:
          this.indexFirst()
          break
        case 2:
          this.selectcourseTypeStatus = true
          break
        case 3:
          this.networkschoolSelectStatus = true
          break
        case 4:
          this.status = true
          break
        case 5:
          this.customlink = true
          break
      }
    },

    // 自定义链接确定
    surecustomlink() {
      if (!this.link.trim()) {
        this.$root.prompt('请输入链接')
        return
      }
      this.list = _.assign({}, this.list, {
        default_type: this.selectType,
        link: this.link,
      })
      this.copylist = this.list
      this.submit()
      this.customlink = false
      this.link = ''
    },

    // 取消自定义链接
    customlinkCancel() {
      const obj = _.merge({}, this.copylist)
      this.list = obj
      this.submit()
      this.customlink = false
      this.reset()
    },

    // // 选择首页
    indexFirst() {
      this.list = _.assign({}, this.list, {
        default_type: this.selectType,
        set_id: 0,
        url_name: '首页',
        link: '',
      })
      this.copylist = this.list
      this.submit()
    },

    // 关闭选择分类的弹窗
    kechengtype() {
      //  判断是否有选择数据，没有选择，恢复到选择前的状态
      // if (Object.keys(this.typeselectlist).length == 0) {
      //  如果没有选择数据,就返回备用数据
      // 不能直接 this.list  = this.copylist  ,否则两者的地址会指向同一个位置
      const obj = _.merge({}, this.copylist)
      this.list = obj
      this.submit()
      this.selectcourseTypeStatus = false
      this.reset()
    },

    // 选择分类
    typeselectval(val) {
      this.list = _.assign({}, this.list, {
        default_type: this.selectType,
        set_id: val.set_id,
        url_name: val.set_name,
        link: '',
      })
      this.copylist = this.list
      this.submit()
    },

    // // 如果选择了数据，那么备用数据就应用当前选择的数据
    sureSelect() {
      this.copylist = _.merge({}, this.list)
    },

    reset() {
      this.link = ''
      this.selectType = ''
      this.selectIndex = ''
    },
  },
}
</script>

<style lang="scss" scoped>
#selectLink {
}
</style>
