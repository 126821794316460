<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-05-30 19:03:06
 * @LastEditTime: 2022-06-02 14:50:15
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @Description: 
 * @FilePath: \teacher.dingdingkaike.com\src\views\NetSchollAdmin\pcSchoolDecoration\centerComponents\livebroadcastPreview2.vue
-->
<template>
  <div class="openClass_model">
    <div class="top">
      <div class="top_font1">{{ value.name }}</div>
      <!-- <div class="top_font2" v-if="value.is_show_more == 1">
        <a href="javaScript:;">查看全部</a>
        <i class="el-icon-d-arrow-right"></i>
      </div> -->
    </div>
    <div class="live_broadcast" v-if="value.list.length > 0">
      <div class="broadcase_left">
        <img :src="selectVal.photo" alt="" />
        <div class="ismask"></div>
        <div class="course_contain">
          <div class="name">{{ selectVal.title }}</div>
          <div class="time">
            <div class="time_contain" v-if="selectVal.status == 1"></div>
            <div class="time_contain" v-else-if="selectVal.down_time > 0"></div>
            <div class="div1" style="top: 38%" v-if="selectVal.status == 1">
              <div class="live_contain">
                <div class="living">
                  <img
                    src="https://f.dingdingkaike.com.cn/pcddkk/live.c1ff313.gif"
                    alt=""
                  />
                </div>
                <span class="span_2">正在直播</span>
              </div>
            </div>

            <div
              class="div1"
              style="transform: translateY(-45%)"
              v-else-if="selectVal.down_time > 0"
            >
              距离开播 &nbsp;
              <span class="span1">
                {{ selectVal._down_time.day || '00' }}
              </span>
              天
              <span class="span1">
                {{ selectVal._down_time.hours || '00' }}
              </span>
              时
              <span class="span1">
                {{ selectVal._down_time.minutes || '00' }}
              </span>
              分
              <span class="span1">
                {{ selectVal._down_time.seconds || '00' }}
              </span>
              秒
            </div>
          </div>
          <a href="javaScript:;" rel="noopener noreferrer">
            <div class="goto_live_broadcast">进入直播间</div>
          </a>
        </div>
        <div class="teacher_info">
          <div class="flex-center" style="flex-shrink: 0; margin-right: 180px;">
            <div class="tphoto">
              <img :src="selectVal.uphoto" alt="" />
            </div>
            <div class="tname">{{ selectVal.uname }}</div>
          </div>
          <div class="fromcourse" v-if="selectVal.type != 3">
            来自课程：{{ selectVal.name }}
          </div>
        </div>
      </div>
      <div class="broadcase_right">
        <!-- <div class="bright">公开课安排</div> -->
        <div class="course_select_contain">
          <div
            class="item"
            v-for="i in value.list"
            :key="i.key"
            @mouseenter="select(i)"
            :class="{ hover: i.key == selectedId }"
          >
            <div class="item_time" v-if="i.islive == 1">正在直播</div>
            <div v-else>
              <div class="item_time" v-if="i.is_taday_tomorrow == 1">
                <span>{{ i.star_time }}</span>
              </div>
              <div class="item_time" style="color:#A1A1A1" v-else>
                <span>{{ i.star_time }}</span>
              </div>
            </div>

            <div
              class="item_name"
              :title="i.title"
              :style="
                i.is_taday_tomorrow == 1 && i.islive != 1 ? 'color:#0AA29B' : ''
              "
            >
              {{ i.title }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="clear: both"></div>
  </div>
</template>

<script>
//隐藏的样式
// import empty from './empty'

export default {
  name: 'openClass',

  components: {
    // empty,
  },

  props: {
    value: Object,
  },

  watch: {
    value: {
      handler(newVal) {
        if (newVal.list && newVal.list.length > 0) {
          this.select(newVal.list[0])
        } else {
          this.selectVal = { _down_time: {} }
        }
      },
      deep: true,
      immediate: true,
    },
  },

  destroyed() {
    clearInterval(this.countdown)
  },

  data() {
    return {
      selectedId: '',
      selectVal: { _down_time: {} },
    }
  },

  methods: {
    select(val) {
      this.selectedId = val.key
      this.selectVal = Object.assign({}, val, {
        _down_time:
          this.$options.filters.formatTimeLength(val.down_time, 3) || {},
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.openClass_model {
  .live_broadcast {
    display: flex;
    width: 100%;
    height: 360px;
    .broadcase_left {
      width: 640px;
      height: 100%;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      position: relative;
      img {
        height: 100%;
        width: 100%;
      }
      .ismask {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: #000000;
        opacity: 0.8;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      }
      .course_contain {
        padding: 0 40px;
        box-sizing: border-box;
        position: absolute;
        height: 181px;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .name {
          font-size: 28px;
          color: #ffffff;
          width: 100%;
          word-break: break-all;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .time {
          position: relative;
          width: 310px;
          height: 38px;
          .time_contain {
            border-radius: 19px;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            opacity: 0.6;
            background: #000000;
          }
          .div1 {
            width: 100%;
            opacity: 1;
            position: absolute;
            margin: 0 auto;
            top: 50%;
            transform: translateY(-29%);
            text-align: center;
            margin: 0 auto;
            font-size: 16px;
            color: #ffffff;

            .span1 {
              color: #fffc22;
            }
            .live_contain {
              display: flex;
              align-items: center;
              width: 100%;
              justify-content: center;
              .living {
                width: 24px;
                height: 24px;
              }
              .span_2 {
                margin-left: 10px;
                font-size: 16px;
                color: #ffffff;
              }
            }
          }
        }
        .goto_live_broadcast {
          cursor: pointer;
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          width: 200px;
          height: 46px;
          background: #0aa29b;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        a {
          text-decoration: none;
        }
      }
      .teacher_info {
        position: absolute;
        left: 20px;
        bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 93%;
        .tphoto {
          width: 46px;
          height: 46px;
          border-radius: 26px;
          border: 1px solid #ffffff;
          background-color: orange;
          margin-right: 20px;
          flex-shrink: 0;
        }
        .tname {
          font-size: 16px;
          color: #ffffff;
          flex-shrink: 0;
        }
        .fromcourse {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
          color: #ffffff;
          font-size: 14px;
        }
      }
    }
    .broadcase_right {
      width: calc(100% - 643px);
      border: 1px #e3e3e3 solid;
      border-left: 0;
      flex: 1;
      height: calc(100% - 2px);
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      .bright {
        margin: 14px 20px 10px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }
      .course_select_contain {
        height: 100%;
        overflow-y: auto;
        @extend %scroll;
        .item {
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: calc(100% - 6px);
          height: 56px;
          background: #fff;
          display: flex;
          align-items: center;
          border-left: 6px #fff solid;
          border-bottom: 1px #ececec solid;

          .item_time {
            width: 86px;
            font-size: 16px;
            color: #0aa29b;
            margin-right: 30px;
            margin-left: 14px;
          }
          .item_name {
            margin-right: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            color: #333333;
          }
        }
        .item:last-child {
          border-bottom-right-radius: 10px;
        }
        .hover {
          background: #f7f7f7 !important;
          border-left: 6px #0aa29b solid !important;
        }
      }
    }
  }
  .top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .top_font1 {
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      line-height: 22px;
    }

    .top_font2 {
      margin-right: 15px;
      font-size: 14px;
      color: #333333;
      line-height: 14px;
      cursor: pointer;
      a {
        text-decoration: none;
        color: #333;
      }
    }
  }
}
</style>
