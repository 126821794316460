<template>
  <div class="my-header">
    <div class="title">导航栏</div>
    <div class="main" ref="list">
      <div v-for="(item, index) in optionlist.list" :key="item.id">
        <template v-if="item.default_type != 9">
          <div class="contain">
            <div class="left_teacher">
              <div class="name">{{ item.name }}</div>
            </div>
            <movebtn
              :index="index"
              :length="optionlist.list.length"
              @upDownMove="upDownMove"
              @remove="removeItem"
            ></movebtn>
          </div>
        </template>
        <template v-else>
          <div class="contain2">
            <movebtn
              :index="index"
              :length="optionlist.list.length"
              @upDownMove="upDownMove"
              @remove="removeItem"
              style="text-align: right"
            ></movebtn>
            <div class="input1">
              <div class="t1">导航名称</div>
              <el-input
                class="t2"
                v-model.trim="item.name"
                placeholder="请输入导航名称"
                maxlength="6"
                show-word-limit
              ></el-input>
            </div>
            <div class="input1">
              <div class="t1">跳转链接</div>
              <el-input
                class="t2"
                v-model.trim="item.link"
                placeholder="请输入跳转链接 https://"
                maxlength="300"
              ></el-input>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div v-if="optionlist.list.length == max" class="add-btn disable">
      + 添加菜单 ({{ optionlist.list.length }}/{{ max }})
    </div>

    <el-popover v-else placement="bottom" width="335" trigger="click">
      <div class="add-btn" slot="reference">
        + 添加菜单 ({{ optionlist.list.length }}/{{ max }})
      </div>
      <div
        v-for="item in addList"
        :key="item.default_type"
        class="pcschool-add-list"
      >
        <div class="item">
          <template v-if="!item.isHas && optionlist.list.length < max">
            <div class="t1 green" @click="add(item)">
              {{ item.name || '添加自定义导航' }}
            </div>
            <div class="t2 green" v-if="item.name" @click="add(item)">
              可添加
            </div>
          </template>
          <template v-else>
            <div class="t1">{{ item.name || '添加自定义导航' }}</div>
            <div class="t2 gray" v-if="item.name">已添加</div>
          </template>
        </div>
      </div>
    </el-popover>
    <el-button id="popo-btn" ref="popo" />
    <bottombtn @submit="submit" @cancel="cancel"></bottombtn>
  </div>
</template>

<script>
import selectdata from './selectdata'
import { mapMutations, mapState } from 'vuex'
import bottombtn from './components/bottomBtns'
import movebtn from './components/moveBtns'

export default {
  name: 'my-header',

  components: {
    bottombtn,
    movebtn,
  },

  mixins: [selectdata],

  data() {
    return {
      max: 6,

      optionlist: _.merge({}, this.selectdata),
      //1  系统默认类型 1 首页 2 即将开课 3 新课推荐 4 热门推荐 5 全部类目 6 会员专区 7 最近浏览 8 搜索栏 9自定义
      addDefaultList: [
        {
          default_type: '1',
          link: '/',
          name: '首页',
        },
        {
          default_type: '2',
          link: '/index/otherpage?source=7',
          name: '即将开课',
        },
        {
          default_type: '3',
          link: '/index/otherpage?source=6',
          name: '新课推荐',
        },
        {
          default_type: '4',
          link: '/index/otherpage?source=5',
          name: '热门推荐',
        },
        {
          default_type: '5',
          link: '/index/otherpage?source=4&activeName=1',
          name: '全部类目',
        },
        {
          default_type: '9',
          link: '',
          name: '',
        },
      ],
    }
  },

  computed: {
    ...mapState('pcSchoolDecration', ['selectdata']),

    addList() {
      return this.addDefaultList.map((item) =>
        Object.assign({}, item, {
          isHas:
            item.default_type != 9 &&
            this.optionlist.list.some(
              (v) => v.default_type == item.default_type
            ),
          id: Math.random(),
        })
      )
    },
  },

  methods: {
    ...mapMutations('pcSchoolDecration', [
      'selectdataChange',
      'selectedChange',
    ]),

    submit() {
      let value
      if (
        (value = this.optionlist.list.find(
          (item) => item.default_type == 9 && (!item.name || !item.link)
        ))
      ) {
        if (!value.name) {
          this.$root.prompt('请先输入导航名称！')
          return
        }
        if (!value.link) {
          this.$root.prompt('请先输入跳转链接！')
          return
        }
      }
      this.success()
    },

    cancel() {
      this.selectedChange('')
    },

    add(item) {
      this.optionlist.list.push(Object.assign({}, item))
      this.$refs.popo.$el.click()
      this.$nextTick(() => {
        this.$refs.list.scrollTop = this.$refs.list.scrollHeight
      })
    },
  },
}
</script>

<style lang="scss">
.pcschool-add-list {
  .item {
    display: flex;
    padding: 8px 20px;
    align-items: center;
    .t1 {
      flex: 1;
      font-size: 14px;
      color: #666666;
      &.green {
        color: #0aa29b;
        cursor: pointer;
      }
    }
    .t2 {
      font-size: 14px;
      cursor: pointer;
      &.green {
        color: #0aa29b;
      }
      &.gray {
        color: #999999;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
#popo-btn {
  width: 0;
  height: 0;
  border: none;
  padding: 0;
  margin: 0;
}
.my-header {
  height: 100%;
  .main {
    max-height: calc(100% - 200px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: #fff;
    }
  }
  .title {
    font-size: 14px;
    color: #333333;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .contain {
    padding: 10px;
    box-sizing: border-box;
    height: 46px;
    background: #f5f5f5;
    border-radius: 2px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left_teacher {
      display: flex;
      align-items: center;
      .name {
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      margin-right: 20px;
    }
  }
  .contain2 {
    padding: 0 10px 10px;
    background: #f5f5f5;
    border-radius: 2px;
    margin-bottom: 10px;
    .input1 {
      display: flex;
      margin-top: 10px;
      align-items: center;
      .t1 {
        font-size: 14px;
        color: #333333;
        white-space: nowrap;
        margin-right: 10px;
      }
      .t2 {
      }
    }
  }
  .add-btn {
    height: 46px;
    margin-top: 10px;
    line-height: 46px;
    background: #f5f5f5;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    color: #0aa29b;
    &.disable {
      color: #999999;
    }
  }
}
</style>
