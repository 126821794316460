<template>
  <div id="modular">
    <div class="all">
      <div
        class="kuai"
        v-for="item in module"
        :key="item.type"
        @click="add(item)"
        :title="item.name"
        :style="item.noOpen ? 'cursor: no-drop' : ''"
      >
        <div class="icon">
          <img :src="item.icon" alt="" />
        </div>
        <div class="name" :class="{ is_no_open: item.noOpen }">
          {{ item.name }}
        </div>
        <div class="no_open" v-if="item.noOpen">未开通</div>
      </div>

      <div class="kuai" @click="floatMenuClick" :title="floatMenu.name">
        <div class="icon">
          <img src="~@ass/img/1.4.1/icon_pczx_xfcd.png" alt="" />
        </div>
        <div class="name">悬浮菜单</div>
      </div>

      <!-- <div class="kuai" @click="floatMenuClick" :title="floatMenu.name">
        <div class="icon">
          <img src="@ass/img/1.4.9/icon_pczx_zbyg.svg" alt="" />
        </div>
        <div class="name">直播预告</div>
      </div> -->
    </div>
    <el-backtop v-show="false" ref="backtop"></el-backtop>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapMutations, mapState } = createNamespacedHelpers('pcSchoolDecration')

export default {
  data() {
    return {
      teacherlist: [], //获取教师列表
      schoollist: [],

      floatMenu: {
        type: 6,
        icon: require('@ass/img/1.4.1/icon_pczx_xfcd.png'),
        name: '悬浮菜单',
        noOpen: false,
      },
    }
  },
  computed: {
    ...mapState(['selected', 'modules', 'pcscale']),

    module() {
      return [
        {
          type: 1,
          icon: require('@ass/img/1.4.1/icon_ggl_mb.png'),
          name: '轮播图',
          noOpen: false,
        },
        {
          type: 2,
          icon: require('@ass/img/1.4.1/icon_kc_mb.png'),
          name: '课程',
          noOpen: false,
        },
        {
          type: 3,
          icon: require('@ass/img/1.4.1/icon_zyb_mb.png'),
          name: '公开课',
          noOpen: false,
        },
        {
          type: 4,
          icon: require('@ass/img/1.4.1/icon_dzzl_mb.png'),
          name: '电子资料',
          noOpen: this.$store.state.userInfo.is_electronics == 2,
        },
        {
          type: 5,
          icon: require('@ass/img/1.4.1/icon_sztd_mb.png'),
          name: '师资团队',
          noOpen: false,
        },
        {
          type: 6,
          icon: require('@ass/img/1.4.9/icon_pczx_zbyg.svg'),
          name: '直播预告-悬浮',
          noOpen: false,
        },
        {
          type: 8,
          icon: require('@ass/img/1.4.9/icon_pczx_zbyg.svg'),
          name: '直播预告-居中',
          noOpen: false,
        },
        {
          type: 7,
          icon: require('@ass/img/1.4.9/icon_pczx_fwb.svg'),
          name: '富文本',
          noOpen: false,
        },
        {
          type: 9,
          icon: require('@ass/img/1.4.9/icon_pczx_tw.svg'),
          name: '图文',
          noOpen: false,
        },
      ]
    },
  },
  created() {
    this.getTeacherlist()
  },

  methods: {
    ...mapMutations(['addModule', 'selectedChange']),

    add({ noOpen, type }) {
      if (noOpen) return

      const ishad = this.modules.some(item => item.data_type == 6 && type == 6)
      if (ishad) {
        this.$root.prompt({
          type: 'warning',
          msg: '该组件最多可添加1个',
        })
        this.selectedChange(this.modules.find(item => item.data_type == 6).id)
        return
      }

      let val
      const id = Math.random()
      switch (type) {
        case 1:
          val = {
            banner: [],
            category: [],
            is_show: '1',
            category_from: '1',
            is_show_category: '1',
            is_show_today_plan: '1',
            id,
            data_type: type,
          }
          break
        case 5:
          val = {
            is_show: '1',
            list: _.merge([], this.teacherlist),
            name: '师资团队',
            show_way: '1',
            sort_num: '5',
            id,
            data_type: type,
          }
          break
        case 2:
        case 4:
        case 9:
          val = {
            id,
            is_show: '1',
            name: type == 2 ? '课程' : type == 9 ? '图文' : '电子资料',
            show_num: 4,
            show_way: '1',
            is_show_num: '1',
            is_show_label: '1',
            is_show_more: '1',
            show_data_type: '1',
            sort_way: '1',
            data_type: type,
            list: [],
          }
          break

        case 3:
          val = {
            name: '公开课',
            is_show: '1',
            is_show_cover_mask: '2',
            live_from: '1',
            id,
            data_type: type,
            list: [],
          }
          break

        case 6:
        case 8:
          val = {
            name: '直播预告',
            is_show: '1',
            is_show_cover_mask: '2',
            is_center: '2',
            is_hover: type == 6 ? '1' : '2',
            live_status: '1',
            course_status: '1',
            small_status: '1',
            time_type: '1',
            days: 1,
            course_type_hide: '2',
            id,
            data_type: type,
            list: [],
          }
          break
        case 7:
          val = {
            name: '富文本',
            is_show: '1',
            is_show_cover_mask: '2',
            id,
            data_type: type,
            content: '',
          }
          break
      }

      if (!val) return

      const isSelected = this.selected
      this.addModule(val)
      this.selectedChange(id)

      this.$root.prompt({
        msg: '添加成功，可在预览区编辑！',
        type: 'success',
      })

      try {
        if (isSelected) {
          this.$nextTick(() => {
            const offsetTop = document.querySelector('.moduleSelect').offsetTop
            document.documentElement.scrollTop =
              offsetTop * this.pcscale - window.innerHeight * 0.3
          })
        } else if (type != 6) {
          setTimeout(() => {
            document.documentElement.scrollTop = 9999999999
          }, 100)
        }
      } catch (e) {
        console.log(e)
      }
    },

    // 悬浮菜单点击
    floatMenuClick() {
      try {
        const scrollTop =
          document.querySelector('.navigation').offsetTop * this.pcscale
        if (document.documentElement.scrollTop > scrollTop) {
          this.$refs.backtop.scrollToTop()
        }
        this.selectedChange(this.modules.find(item => item.data_type == -2).id)
      } catch (e) {
        console.info(e)
      }
    },

    // 获取老师列表
    getTeacherlist() {
      this.$http({
        url: '/HomeTemplate/teacherList',
        data: {
          type: 1,
        },
        callback: ({ data }) => {
          this.teacherlist = data
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#modular {
  .is_no_open {
    color: #bbbbbb !important;
  }
  .all {
    width: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .kuai {
    position: relative;
    cursor: pointer;
    width: 85px;
    height: 85px;
    background: #f5f5f5;
    border-radius: 4px;
    margin-bottom: 10px;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover {
      background: #e2e2e2;
    }
    .no_open {
      position: absolute;
      right: 0;
      top: 0;
      background: #ffcdcd;
      font-size: 12px;
      color: #ff3535;
      width: 46px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .icon {
      img {
        margin: 0 auto;
        max-width: 24px;
        max-height: 24px;
      }
    }
    .name {
      font-size: 12px;
      color: #666666;
      margin-top: 10px;
    }
  }
}
</style>
