<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-05-12 18:18:22
 * @LastEditTime: 2022-05-23 14:30:32
 * @LastEditors: cyy
 * @Description: 微页面选择
 * @FilePath: \teacher.dingdingkaike.com\src\components\templatelistSelect.vue
-->
<template>
  <div class="templatelistSelect">
    <el-dialog
      title="选择微页面"
      class="dialogVisible"
      width="422px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="true"
      :destroy-on-close="true"
      :modal-append-to-body="true"
      :append-to-body="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <el-radio-group v-model="radio">
          <el-radio
            :label="item"
            v-for="item in datalist"
            :key="item.pc_t_home_id"
          >
            {{ item.name }}
          </el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="medium">取 消</el-button>
        <el-button type="primary" size="medium" @click="complete">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'templatelistSelect',
  data() {
    return {
      radio: {},
      datalist: [],
      dialogVisible: false,
    }
  },
  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.gettemplateList()
  },

  methods: {
    async gettemplateList() {
      const { data } = await this.$http({
        url: '/PcHomeTemplate/templateList',
      })
      this.datalist = data
    },

    complete() {
      if (Object.keys(this.radio).length == 0) {
        this.$root.prompt('请先选择微页面！')
        return
      }
      this.$emit('selectObj', this.radio)
      this.$emit('update:dialogstatus', false)
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('cancelSelect')
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 10px 20px !important;
  max-height: 356px;
  overflow: auto;
  @extend %scroll;
}

::v-deep .el-dialog__footer {
  padding-top: 0;
}
.el-dialog__title {
  font-size: 14px;
  color: #333333;
  line-height: 19px;
}
.dialogVisible_bottom {
  .el-radio {
    display: block;
    margin-bottom: 20px;
  }

  // .el-dialog__header {
  //   padding-top: 14px;
  // }
}
</style>
