<template>
  <div class="banner">
    <div class="left">
      <div
        class="courseconatin"
        @mouseleave="mouseleave"
        v-if="value.is_show_category == 2"
      >
        <template v-if="value.category.length > 0">
          <div class="course_type_select">
            <div
              class="item"
              v-for="item in value.category"
              :key="item.id"
              @mouseenter="mouseenter(item)"
              :class="{ itembg: item.id === childId }"
            >
              <div class="rank_first">
                <span class="set_name" :title="item.category_name">
                  {{ item.category_name }}
                </span>
                <i class="el-icon-arrow-right"></i>
              </div>
              <div class="rank_tow">
                <div
                  class="rank_tow_item"
                  v-for="i in item.children.length > 2
                    ? item.children.slice(0, 2)
                    : item.children"
                  :key="i.id"
                  :title="i.category_name"
                >
                  {{ i.category_name }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="childId !== '' && childCategory.length > 0"
            class="course_type_select2 children-type"
          >
            <div v-for="(v, index) in 2" :key="v" class="child">
              <div
                class="item"
                v-for="item in childCategory.slice(index * 5, v * 5)"
                :key="item.pc_banner_component_category_id"
              >
                <div class="three_rank">
                  <div class="three_item">
                    <div
                      class="tow_title"
                      :title="item.category_name"
                      :href="item.link || 'javascript:;'"
                      :target="item.blank ? '_blank' : '_self'"
                    >
                      {{ item.category_name }}
                    </div>
                    <div class="children_three_title">
                      <div
                        class="three_title"
                        v-for="i in item.children"
                        :key="i.pc_banner_component_category_id"
                        :href="i.link || 'javascript:;'"
                        :target="i.blank ? '_blank' : '_self'"
                      >
                        <div class="name" :title="i.category_name">
                          {{ i.category_name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <tempalte v-else>
          <div class="cateEmpty">未添加分类导航</div>
        </tempalte>
      </div>
    </div>
    <el-carousel
      height="468px"
      :arrow="value.banner.length > 1 ? 'hover' : 'never'"
      class="my-swipe"
      :class="{ emptyleft: value.is_show_category == 1 }"
      v-if="value.banner.length > 0"
      :indicator-position="value.banner.length > 1 ? '' : 'none'"
    >
      <!-- :arrow="value.banner.length > 1 ? 'hover' : 'never'" -->
      <el-carousel-item v-for="item in value.banner" :key="item.id">
        <img class="banner-img" :src="item.url" alt="" />
      </el-carousel-item>
    </el-carousel>
    <template v-else>
      <div class="my-swipe empty">
        <empty></empty>
      </div>
    </template>
    <!-- <div class="login-myCourse" v-if="value.is_show_today_plan == 1">
      <div class="user-center">
        <div class="login">
          <div class="login-title">登录后查看上课安排</div>
          <div class="login-img"></div>
          <button class="login-btn">点我登录</button>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
//空状态
import empty from './empty'

export default {
  name: 'banner',

  components: {
    empty,
  },

  data() {
    return {
      // 子type id
      childId: '',
    }
  },

  props: {
    value: Object,
  },

  computed: {
    childCategory() {
      return (
        (this.value.category.find(item => item.id == this.childId) || [])
          .children || []
      )
    },
  },

  methods: {
    mouseenter({ id }) {
      this.childId = id
    },

    mouseleave() {
      this.childId = ''
    },
  },
}
</script>
<style lang="scss" scoped>
%pointer {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.banner {
  $height: 468px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: $height;
  .left {
    position: absolute;
    left: 0;
    top: 0;
    .courseconatin {
      position: relative;
      z-index: 4;
      .course_type_select {
        // display: flex;
        // flex-direction: column;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        height: $height;
        width: 260px;
        padding: 10px 0;
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, 0.8);
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        .itembg {
          background: rgba(43, 96, 93, 1);
        }
        .item {
          padding-left: 30px;
          padding: 0px 20px;
          height: 74px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          box-sizing: border-box;
          // display: -webkit-box;
          // display: -ms-flexbox;
          // display: flex;
          // -webkit-box-align: center;
          // -ms-flex-align: center;
          // align-items: center;
          font-size: 13px;
          color: #fff;
          &:hover {
            background: rgba(43, 96, 93, 1);
          }
          .rank_first {
            font-size: 16px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .set_name {
              flex: 1;
              color: #fff;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-break: break-all;
              @extend %pointer;
            }
            i {
              flex-shrink: 0;
            }
          }
          .rank_tow {
            margin-top: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            .rank_tow_item {
              color: #b4b4b4;
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              @extend %pointer;
            }
          }
        }
      }
      .cateEmpty {
        background-color: rgba(0, 0, 0, 0.8);
        height: 468px;
        width: 260px;
        font-size: 12px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .course_type_select2 {
        white-space: nowrap;
        height: 468px;
        box-shadow: 4px 2px 9px 0px rgba(0, 0, 0, 0.13);
        background-color: #fff;
        > .child {
          display: inline-block;
          vertical-align: top;
          height: 100%;
          &:last-child {
            .item {
              margin-left: 48px;
            }
          }
          .item {
            height: 20%;
            overflow: hidden;
            .three_rank {
              display: flex;
              line-height: 1.5;
              justify-content: space-between;
              .three_item {
                .tow_title {
                  font-size: 16px;
                  color: #333333;
                  @extend %pointer;
                  display: block;
                  margin-bottom: 6px;
                }
                .children_three_title {
                  display: grid;
                  grid-template-columns: repeat(3, auto);
                  grid-column-gap: 48px;
                  grid-row-gap: 7px;
                  .three_title {
                    font-size: 13px;
                    color: #666666;
                    // display: flex;
                    // align-items: center;
                    display: block;
                    .name {
                      // flex: 1;
                      @extend %pointer;
                      // overflow: hidden;
                      // text-overflow: ellipsis;
                      // white-space: nowrap;
                    }
                  }
                }
              }
            }
          }
        }
        &.children-type {
          overflow-y: auto;
          box-sizing: border-box;
          padding: 20px 20px 0 20px;
          z-index: 5;
          position: absolute;
          top: 0;
          left: 260px;
          border-radius: 0;
          font-size: 0;
          &::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 6px;
            /*高宽分别对应横竖滚动条的尺寸*/
            height: 1px;
          }

          &::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            cursor: pointer;
            border-radius: 10px;
            background: rgba(181, 175, 175, 0.5);
          }
          &::-webkit-scrollbar-thumb:hover {
            /*滚动条里面小方块*/
            background: rgba(181, 175, 175, 1);
          }

          &::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            border-radius: 0;
            background: transparent;
          }
        }
      }
    }
  }

  .my-swipe {
    width: 100%;
    overflow: hidden;
    position: relative;
    background: #000;
    border-radius: 12px;
    overflow: hidden;
    &.empty {
      background: none;
      width: 100%;
    }

    .banner-img {
      width: 100%;
      height: 100%;
    }

    .el-carousel__arrow {
      width: 52px;
      height: 52px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 0;
    }
    .el-carousel__arrow--left {
      left: 260px;
    }
    .el-carousel__arrow--right {
      right: 0;
    }

    .el-icon-arrow-left,
    .el-icon-arrow-right {
      font-size: 25px;
      color: rgba(255, 255, 255, 0.8);
    }

    .el-carousel__button {
      opacity: 1;
      width: 6px;
      height: 6px;
      background: #ffffff;
      border-radius: 4px;
    }

    .el-carousel__indicator--horizontal {
      padding: 12px 6px;
    }
    .el-carousel__indicator.is-active .el-carousel__button {
      width: 30px;
      height: 6px;
      background: #ffffff;
      border-radius: 4px;
    }
  }

  .emptyleft {
    .el-carousel__arrow--left {
      left: 0;
    }
  }

  .login-myCourse {
    width: 280px;
    height: 468px;
    background: #fff;
    margin-left: 20px;
    position: relative;
    border-radius: 4px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
    .login {
      text-align: center;
      padding: 26px 0 30px;
      .login-title {
        color: grey;
        font-size: 16px;
        line-height: 21px;
      }
      .login-img {
        width: 254px;
        height: 150px;
        margin: 61px auto 29px;
        background: url(https://f.dingdingkaike.com.cn/pcddkk/icon_wdlch.svg)
          no-repeat;
        background-size: contain;
      }
      .login-btn {
        border: 0;
        width: 200px;
        margin: 0 auto;
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        line-height: 46px;
        border-radius: 4px;
        background: #0aa29b;
        display: block;
        text-align: center;
        color: #fff;
      }
    }
  }
}
</style>
