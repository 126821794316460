<!--
 * @Author: cyy
 * @version:  navsort
 * @Date: 2022-05-17 15:33:55
 * @LastEditTime: 2022-05-18 17:45:21
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacher.dingdingkaike.com\src\views\NetSchollAdmin\pcSchoolDecoration\editComponents\components\navsort.vue
-->
<template>
  <div id="navsort">
    <el-dialog
      title="开通功能"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :modal-append-to-body="true"
      :append-to-body="true"
      @close="cancel"
    >
      <div slot="title">
        管理导航
        <span class="redcolor ml10 fs12">
          提示：排序只对同级导航有效，不支持跨级排序
        </span>
      </div>
      <div class="dialogVisible_bottom"></div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'navsort',
  data() {
    return {
      dialogVisible: false,
    }
  },
  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
#navsort {
  ::v-deep .el-dialog {
    min-height: 500px;
  }
  ::v-deep .el-dialog__footer{
      position: absolute;
      bottom: 0;
      right: 0;
  }
  .dialogVisible_bottom {
  }
}
</style>
