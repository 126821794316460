<template>
  <div class="course_model">
    <div class="top">
      <div class="top_font1">{{ value.name }}</div>
      <div class="top_font2" v-if="value.is_show_more == 1">
        <a href="javaScript:;">查看全部</a>
        <i class="el-icon-d-arrow-right"></i>
      </div>
    </div>
    <ul class="course-list">
      <li
        v-for="item in value.list.slice(0, value.show_num)"
        :key="item.course_id"
        class="item"
      >
        <a class="href" href="javaScript:;">
          <div class="cover">
            <img class="img" :src="item.photo" alt="" />
          </div>
          <div class="content">
            <div class="title">
              {{ item.name }}
            </div>
            <div class="detail">
              <template v-if="item.course_type == 1">
                <!-- 直播课 -->
                <img
                  src="https://f.dingdingkaike.com.cn/pcddkk/ico_zbbs.svg"
                  class="tag"
                />
              </template>

              <template v-if="item.course_type == 4">
                <!-- 系列课 -->
                <img
                  src="https://f.dingdingkaike.com.cn/pcddkk/ico_xlbs.svg"
                  class="tag"
                />
              </template>

              <template v-if="item.is_play == 1">
                <!-- 试看 -->
                <img
                  src="https://f.dingdingkaike.com.cn/pcddkk/ico_skbs.svg"
                  class="tag"
                />
              </template>

              <template v-if="item.course_type == 3">
                <!-- 录播 -->
                <img
                  src="https://f.dingdingkaike.com.cn/pcddkk/ico_lbbs.svg"
                  class="tag"
                />
              </template>

              <template v-if="item.course_type == 2">
                <!-- 小班 -->
                <img
                  src="https://f.dingdingkaike.com.cn/pcddkk/ico_xbbs.svg"
                  class="tag"
                />
              </template>
              <span class="number">
                <span>共</span>
                <span>{{ item.count }}</span>
                <span v-if="item.course_type == 4">期</span>
                <span v-else>节</span>
                <span v-if="item.is_show_sales == 1 && item.number > 0">
                  | {{ item.number }}人学习
                </span>
              </span>
            </div>
            <div class="price">
              <div class="t1" v-if="item.price > 0">￥{{ item.price }}</div>
              <div class="t1" v-else>免费</div>
              <div class="t2" v-if="item.costprice > 0">
                ￥{{ item.costprice }}
              </div>
              <div class="isvip" v-if="item.is_vip == 1">
                <img
                  src="https://f.dingdingkaike.com.cn/pcddkk/icon_vip6.svg"
                />
              </div>
            </div>
          </div>
        </a>
      </li>
      <li v-for="item in 4 - (value.list.length % 4)" :key="item" class="item">
        <a class="href block" href="javaScript:;"></a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'courses',

  props: {
    value: Object,
  },

  components: {},
}
</script>
<style lang="scss" scoped>
@import './courseItem.scss';
</style>
