<template>
  <div id="facultyTeam">
    <div class="module-title">师资团队</div>
    <div class="title">模块标题</div>
    <el-input
      type="text"
      placeholder="请输入模块标题"
      v-model="optionlist.name"
      maxlength="15"
      show-word-limit
    ></el-input>
    <div class="title mt30">展示方式</div>
    <el-radio-group v-model="optionlist.show_way">
      <el-radio label="1">轮播图</el-radio>
      <el-radio label="2">排列图</el-radio>
    </el-radio-group>
    <div class="title mt30">展示内容</div>
    <div class="main">
      <div
        class="contain"
        v-for="(item, index) in optionlist.list"
        :key="item.team_component_teacher_id"
      >
        <div class="left_teacher">
          <div class="tu">
            <img :src="item.uphoto" alt="" />
          </div>
          <div class="name">{{ item.uname }}</div>
        </div>
        <movebtn
          :index="index"
          :length="optionlist.list.length"
          @upDownMove="upDownMove"
          @remove="removeItem"
        ></movebtn>
      </div>
      <div class="contain2" @click="addteacher">
        <div class="add_teacher">+添加老师</div>
      </div>
    </div>

    <bottombtn @submit="submit" @cancel="selectedChange('')"></bottombtn>
  </div>
</template>

<script>
import selectdata from './selectdata'
import { mapMutations, mapState } from 'vuex'
import bottombtn from './components/bottomBtns'
import movebtn from './components/moveBtns'

export default {
  mixins: [selectdata],

  components: {
    bottombtn,
    movebtn,
  },

  data() {
    return {
      value: '',
      text: '',
    }
  },
  computed: {
    ...mapState('pcSchoolDecration', ['selectdata', 'selectedteacherlist']),
  },
  watch: {
    selectedteacherlist: {
      handler() {
        if (this.selectedteacherlist.length == 0) {
          return
        }
        this.optionlist.list.push(...this.selectedteacherlist)
        this.selectedteacherChange([])
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapMutations('pcSchoolDecration', [
      'teacherSelect',
      'selectedChange',
      'selectdataChange',
      'selectedteacherChange',
    ]),
    addteacher() {
      const teacherids = this.optionlist.list.map(
        item => item.teacher_id || item.uid
      )
      const val = { isopen: teacherids }
      this.teacherSelect(val)
    },
    submit() {
      this.optionlist.name = this.optionlist.name.trim()
      this.success()
    },
    cancel() {
      this.selectedChange('')
    },
    //移除老师
    confirm(val) {
      this.optionlist.list.splice(val, 1)
    },
    //上移 下移
    upDownMove(index, newIndex) {
      let optionlist = this.optionlist.list
      const oldData = optionlist[newIndex]
      optionlist.splice(newIndex, 1, optionlist[index])
      optionlist.splice(index, 1, oldData)
    },
  },
}
</script>

<style lang="scss" scoped>
#facultyTeam {
  height: 100%;
  overflow: auto;
  height: calc(100% - 98px);
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: #fff;
  }
  .module-title {
    font-size: 14px;
    color: #333333;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .title {
    font-size: 14px;
    color: #333333;
    margin-bottom: 10px;
  }
  .contain,
  .contain2 {
    padding: 10px;
    box-sizing: border-box;
    height: 50px;
    background: #f5f5f5;
    border-radius: 2px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left_teacher {
      flex: 1;
      display: flex;
      overflow: hidden;
      align-items: center;
      .tu {
        img {
          width: 30px;
          height: 30px;
          border-radius: 4px;
          margin-right: 10px;
        }
      }
      .name {
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      margin-right: 20px;
    }
    .add_teacher {
      display: flex;
      align-items: center;
      margin: 0 auto;
      font-size: 14px;
      color: #1b9d97;
    }
  }

  .contain2 {
    cursor: pointer;
  }
}
</style>
