<template>
  <footer id="footer">
    <img class="logo" :src="userInfo.jg_logo" alt="" />
    <div class="number">
      Copyright © {{ userInfo.jg_title }} All Rights Reserved 粤ICP备15114995号
    </div>
    <div class="ddkk">叮叮开课提供技术支持</div>
  </footer>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'myFooter',

  computed: {
    ...mapState(['userInfo']),
  },
}
</script>
<style lang="scss" scoped>
#footer {
  padding: 30px 0;
  text-align: center;
  background: #181818;
  .logo {
    width: 36px;
    height: 36px;
    margin: 0 auto;
    background: #d8d8d8;
    border-radius: 4px;
  }
  .number {
    font-size: 12px;
    color: #7f7f7f;
    margin-top: 30px;
  }
  .ddkk {
    font-size: 12px;
    color: #7f7f7f;
    margin-top: 20px;
  }
}
</style>
