<template>
  <div id="headNavigation">
    <div class="left">
      <div class="goback" @click="goback">
        <i class="el-icon-arrow-left"></i>
        <div class="backfont">返回后台</div>
      </div>
    </div>
    <div class="center">{{ $route.query.name }}</div>
    <div class="right">
      <el-button
        size="medium "
        style="border: #0aa29b 1px solid; color: #0aa29b"
        @click="save(1)"
      >
        发布并预览
      </el-button>
      <el-button
        type="primary"
        size="medium "
        style="width: 110px"
        @click="save(2)"
      >
        发布
      </el-button>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  'pcSchoolDecration'
)

export default {
  computed: {
    ...mapState(['modules', 'originModules', 'pcSchoolUrl']),
  },

  methods: {
    ...mapActions(['networkSchoolDecration']),
    ...mapMutations(['selectedChange']),

    gotoHome() {
      this.$router.push('/pcSchool')
    },

    // 返回
    goback() {
      if (
        this.originModules.length > 0 &&
        _.isEqual(this.originModules, this.modules)
      ) {
        this.gotoHome()
      } else {
        this.$confirm('此时离开将丢失已编辑的内容, 是否离开?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.gotoHome()
        })
      }
    },

    // 保存
    // 发布
    save(val) {
      const data = _.merge([], this.modules)
      const floatMenu = data.splice(
        data.findIndex(item => item.data_type == -2),
        1
      )[0]
      const navbar = data.splice(
        data.findIndex(item => item.data_type == -1),
        1
      )[0]
      this.$confirm(
        '发布前请确认修改的内容已保存，发布后将覆盖之前的网校内容，是否发布？',
        '发布提示',
        {
          confirmButtonText: '确定发布',
          cancelButtonText: '取消',
        }
      )
        .then(() => {
          this.$http({
            url: '/pcHomeTemplate/preservation',
            data: {
              data,
              floatMenu,
              navbar: navbar.list || [],
              pc_t_home_id: this.$route.query.id,
            },
            callback: ({ code }) => {
              if (code == 200) {
                if (val == 2) {
                  this.$root.prompt({
                    type: 'success',
                    msg: '保存成功！',
                  })
                } else {
                  this.$store.dispatch('open', this.pcSchoolUrl)
                }

                this.networkSchoolDecration(this.$route.query.id)
                this.selectedChange()
                // this.$router.push('/pcSchool')
              }
            },
          })
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
#headNavigation {
  position: fixed;
  width: 100%;
  z-index: 999;
  height: 58px;
  background-color: #fff;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  .left {
    display: flex;

    .goback {
      padding: 21px 20px;
      display: flex;
      cursor: pointer;

      .backfont {
        margin-left: 5px;
        font-size: 16px;
        color: #333333;
      }
    }

    .dirver {
      width: 2px;
      height: 58px;
      background-color: #f5f5f5;
      margin-right: 10px;
    }

    .select_type {
      padding: 10px;

      .el-input__inner {
        border: 0;
      }
    }
  }

  .center {
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #333333;

    i {
      margin-left: 8px;
    }
  }

  .right {
    margin-right: 20px;

    .cbotton {
      color: #0aa29b;
    }
  }
}
</style>
