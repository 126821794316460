<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-06-01 10:12:49
 * @LastEditTime: 2022-06-10 18:46:48
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacher.dingdingkaike.com\src\views\NetSchollAdmin\pcSchoolDecoration\centerComponents\richtext.vue
-->
<template>
  <div class="richtext">
    <div v-if="value.content">
      <div v-html="value.content"></div>
    </div>
    <div class="empty" v-else>
      <img src="@ass/img/1.4.9/icon_pczx_fwb.svg" alt="" />
      <div class="tip">请点击右侧编辑按钮进入富文本编辑器</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'richtext',
  data() {
    return {}
  },
  props: {
    value: Object,
  },
}
</script>

<style lang="scss" scoped>
.richtext {
  ::v-deep img {
    display: inline-block !important;
    max-width: 1200px;
  }
  .empty {
    height: 328px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      width: 40px;
      height: 40px;
    }
    .tip {
      margin-top: 20px;
      font-size: 12px;
      color: #666666;
    }
  }
}
</style>
