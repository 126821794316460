<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-06-01 10:12:18
 * @LastEditTime: 2022-06-01 16:53:53
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacher.dingdingkaike.com\src\views\NetSchollAdmin\pcSchoolDecoration\editComponents\richtext.vue
-->
<template>
  <div class="richtext">
    <div class="module-title">富文本</div>
    <el-button class="btnrich" @click="dialogVisible = true">
      编辑富文本
    </el-button>

    <bottombtn @submit="submit" @cancel="selectedChange('')"></bottombtn>

    <el-dialog
      title="编辑富文本"
      :append-to-body="true"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
      v-if="dialogVisible"
    >
      <div class="contain">
        <editor ref="editor" class="editor" v-model="content"></editor>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="cancel" style="width:100px" size="medium">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click.native="save"
          style="width:100px"
          size="medium"
        >
          保存
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import editor from '@cm/editor'
import selectdata from './selectdata'
import bottombtn from './components/bottomBtns'
export default {
  mixins: [selectdata],

  components: {
    editor,
    bottombtn,
  },
  computed: {},

  data() {
    return {
      content: '',
      dialogVisible: false,
    }
  },

  created() {
    this.content = this.optionlist.content
  },

  watch: {
    'optionlist.id'() {
      this.content = this.optionlist.content
    },
  },

  methods: {
    ...mapMutations('pcSchoolDecration', [
      'selectChange',
      'selectdataChange',
      'selectedChange',
    ]),

    cancel() {
      this.content = this.optionlist.content
      this.dialogVisible = false
    },

    save() {
      this.optionlist = _.assign({}, this.optionlist, { content: this.content })
      this.dialogVisible = false
    },

    submit() {
      const val = _.merge({}, this.optionlist)
      this.selectdataChange(val)
      this.successPrompt()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0 20px 20px;
}
::v-deep .editor {
  max-width: 100%;
}
::v-deep .el-dialog__footer {
  background: #ffffff;
  text-align: center !important;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.08);
}
.contain {
  //   height: 385px;
  //   overflow: auto;
  //   @extend %scroll;
}
.richtext {
  ::v-deep .btnrich {
    border-color: #0aa29b;
    color: #0aa29b;
    width: 100%;
    margin-top: 30px;
  }
}
</style>
