<template>
  <div id="advertisement">
    <div class="title">轮播图</div> 
    <div class="switch1 mb20">
      <div class="title2 mr30">样式选择</div>
      <el-radio-group v-model="optionlist.is_show_category">
        <el-radio label="1">轮播图</el-radio>
        <el-radio label="2">分类导航 + 轮播图</el-radio>
      </el-radio-group> 
    </div>
    <template v-if="optionlist.is_show_category == 2">
      <div class="title2 mb20">分类导航</div>
      <el-button
        style="width:375px;border-color:#0AA29B;color:#0AA29B"
        class="mb30"
        @click="navManager"
      >
        管理导航
      </el-button>
    </template>
    <div class="title2 mb20">轮播图</div>
    <div class="main" ref="list"> 
      <!-- bannner图 -->
      <div class="advertisement">
        <div
          class="more mb10"
          v-for="(item, index) in optionlist.banner"
          :key="item.id"
        >
          <div class="up_dw_dl">
            <movebtn
              :index="index"
              removeText="删除"
              :length="optionlist.banner.length"
              @upDownMove="upDownMove(...arguments, 'banner')"
              @remove="removeItem(...arguments, 'banner')"
              style="text-align: right"
            ></movebtn>
          </div>

          <div class="contain_ad">
            <div class="tu" @click="changgePicturec(item.id)">
              <img :src="item.url" alt="" v-if="item.url" />
              <div class="tu_zi" v-else>点击选择图片</div>
              <div class="tu_zi2">
                <p>更换图片</p>
                <p>推荐1200*468px</p>
              </div>
            </div>
            <div class="right">
              <div class="ad_font1">跳转模块</div>
              <select-link
                v-show="!item.default_type"
                :key="index"
                :index="index"
                :value="item"
                :id="1"
                :ref="`selectlinkref${item.id}`"
                @compelet="compelet2"
              ></select-link>
              <el-input
                v-show="item.default_type"
                size="small"
                style="width: 210px; margin-right: 14px"
                :value="
                  `${getType(item.default_type)} | ${item.url_name ||
                    item.link} `
                "
                clearable
                @clear="clear($event, item, index)"
              ></el-input>
            </div>
          </div>
          <span class="tip mt10" style="margin-left:6px">
            建议尺寸：1200*468
          </span>
        </div>
        <div
          class="more"
          style="padding-top: 10px"
          @click="optionlist.banner.length >= 10 ? '' : addmore()"
          :style="optionlist.banner.length >= 10 ? 'cursor: no-drop;' : ''"
        >
          <div class="more_font1">+新增广告图</div>
          <div class="tip">最多可添加10张广告图</div>
        </div>
      </div>
    </div>

    <!-- 导航管理 -->
    <navigation-Management
      v-if="status"
      :category="optionlist.category"
      @complete="complete"
      :dialogstatus.sync="status"
    ></navigation-Management>

    <bottombtn @submit="submit" @cancel="cancel"></bottombtn>
  </div>
</template>

<script>
import selectLink from '@cm/selectLink'
import navigationManagement from './components/navigationManagement.vue'
import selectdata from './selectdata'
import { mapMutations, mapState } from 'vuex'
import bottombtn from './components/bottomBtns'
import movebtn from './components/moveBtns'

export default {
  mixins: [selectdata],

  components: {
    bottombtn,
    movebtn,
    navigationManagement,
    selectLink,
  },
 
  data() {
    return {
      aa: true,
      status: false,
      moduleName: '1',
      selctindex: '', //list中被选中的索引
      selectpicture: '', //当前选中模块的id 
      value2: [''],
      optionlist: _.merge({}, this.selectdata),
    }
  },
  computed: {
    ...mapState('pcSchoolDecration', [
      'isSelectPicture',
      'singleselectcontentval',
      'selectdata',
      'typeselectval',
    ]),
  },

  watch: {
    // 改变当前选中修改图像的值
    isSelectPicture() {
      const index = this.optionlist.banner.findIndex(
        item => item.id == this.selectpicture
      )
      const val = _.assign({}, this.optionlist.banner[index], {
        url: this.isSelectPicture.url,
      })

      this.optionlist.banner.splice(index, 1, val)
    },
    //改变指定网校内容
    singleselectcontentval() {
      const index = this.optionlist.banner.findIndex(
        item => item.id == this.selectpicture
      )
      //没有选中课程的情况
      if (!this.singleselectcontentval.name) {
        return
      }
      const val = _.assign({}, this.optionlist.banner[index], {
        content_id:
          this.singleselectcontentval.type == 1
            ? this.singleselectcontentval.ed_id
            : this.singleselectcontentval.type == 2
            ? this.singleselectcontentval.member_id
            : this.singleselectcontentval.type == 3
            ? this.singleselectcontentval.workbook_id
            : this.singleselectcontentval.course_id,
        content_name: this.singleselectcontentval.name,
        content_type: this.singleselectcontentval.type,
      })
      this.optionlist.banner.splice(index, 1, val)
    },

    //改变课程分类的数据
    typeselectval(val) {
      this.optionlist.category = [].concat(this.optionlist.category, val)
    },

    'optionlist.category_from'(val) {
      let category = []
      // 如果切换到初始类型时，数据进行重置
      if (val == this.selectdata.category_from) {
        category = _.merge([], this.selectdata.category)
      }
      this.optionlist = _.assign({}, this.optionlist, {
        category,
      })
    },
  },

  methods: {
    ...mapMutations('pcSchoolDecration', [
      'changgePicture',
      'kechengtype',
      'kechengtype2',
      'selectChange',
      'selectdataChange',
      'selectedChange',
    ]),

    getType(val) {
      let text = ''
      switch (Number(val)) {
        case 2:
          text = '课程分类'
          break
        case 3:
          text = '商品'
          break
        case 4:
          text = '电脑网校微页面'
          break
        case 5:
          text = '自定义链接'
          break
      }
      return text
    },

    // 清除
    clear(event, item, index) {
      this.$refs[`selectlinkref${item.id}`][0].clearfn(index)
    },

    navManager() {
      this.$nextTick(() => {
        this.status = true
      })
    },

    // 获取轮播图链接的数据
    compelet2({ val, index }) {
      const obj = _.merge({}, val)
      const arr = []
      this.optionlist.banner.forEach((item, index2) => {
        if (index == index2) {
          item = _.assign({}, item, obj)
        }
        arr.push(item)
      })
      this.optionlist = _.assign({}, this.optionlist, { banner: arr })
      this.$forceUpdate()
    },

    complete(val) {
      this.optionlist = _.assign({}, this.optionlist, { category: [...val] })
    },

    submit() {
      const val = this.optionlist.banner.findIndex(item => item.url == '')
      if (val > -1 && this.optionlist.banner.length > 0) {
        this.$root.prompt({
          msg: '请添加广告图片！',
        })
      } else {
        this.success()
      }
    },

    cancel() {
      this.selectedChange('')
    },

    //移除数据
    remove(val) {
      const index = this.optionlist.banner.findIndex(item => item.id == val)
      const val3 = _.assign({}, this.optionlist.banner[index], {
        content_id: '',
        content_name: '',
        content_type: '',
      })
      this.optionlist.banner.splice(index, 1, val3)
    },

    // 获取分类数据
    init() {
      // 有分类数据数据 或者没开启分类  或者是自定义分类 才去获取
      if (
        this.optionlist.category.length > 0 ||
        this.optionlist.is_show_category == 2 ||
        this.optionlist.category_from == 2
      )
        return
      // this.getData()
    },
 

    //改变类型时，清空 content_id: '', content_name: '', content_type: '',三个数据
    changeTypet(val, val2) {
      const index = this.optionlist.banner.findIndex(item => item.id == val2)
      const val3 = _.assign({}, this.optionlist.banner[index], {
        content_id: '',
        content_name: '',
        content_type: '',
      })
      this.optionlist.banner.splice(index, 1, val3)
    },

    //更换图片
    changgePicturec(val) {
      this.selectpicture = val
      this.changgePicture(true)
    },

    // 课程分类
    kechengtypec(val) {
      this.selectpicture = val
      this.kechengtype2(this.optionlist.category.map(item => item.set_id))
    },

    //指定网校内容
    zhidingtype(val) {
      this.selectpicture = val
      this.selectChange(true)
    },

    // 添加更多图片
    addmore() {
      this.optionlist.banner.push({
        url: '',
        default_type: '',
        commodity_type: '',
        link: '',
        content_id: '',
        id: Math.random(),
      })
      this.$nextTick(() => {
        this.$refs.list.scrollTop = this.$refs.list.scrollHeight
      })
    },

    // 同步手机网校数据
    synchronouMobileData() {
      this.$http({
        url: '/pcHomeTemplate/syncBanner',
        callback: ({ data }) => {
          this.optionlist.banner.push(...data)
          if (data.length == 0) {
            this.$root.prompt('暂无数据！')
          }
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#advertisement {
  overflow: auto;
  height: 100%;
  ::v-deep .el-select {
    width: 210px !important;
  }

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: #fff;
  }
  .title2 {
    font-size: 14px;
    color: #333333;
  }
  .title {
    font-size: 14px;
    color: #333333;
    margin-bottom: 10px;
    font-weight: bold;
  }
  ::v-deep .my-el-tabs__nav {
    .el-tabs__nav-wrap {
      font-size: 0;
      text-align: center;
    }
    .el-tabs__nav-scroll {
      display: inline-block;
    }
    .el-tabs__nav-wrap::after {
      height: 1px;
    }
  }
  .main {
    overflow: auto;
    margin-bottom: 120px;
    // height: calc(100% - 79px - 70px);
  }
  .up_dw_dl {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    color: #1b9d97;
    .driver {
      width: 1px;
      height: 12px;
      background-color: #1b9d97;
      margin: 0 10px;
    }
    span {
      cursor: pointer;
    }
  }
  .zhiding {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .name {
      width: calc(100% - 48px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      color: #333333;
      margin-right: 20px;
    }
    .update {
      cursor: pointer;
      font-size: 14px;
      color: #1b9d97;
    }
  }
  .advertisement {
    overflow: auto;
    .more {
      width: 100%;
      padding: 0 10px 10px;
      box-sizing: border-box;
      background: #f5f5f5;
      border-radius: 2px;
      cursor: pointer;

      .more_font1 {
        text-align: center;
        font-size: 14px;
        color: #0aa29b;
        margin: 43px 0 32px;
      }
      .tip {
        font-size: 12px;
        color: #999999;
      }

      .contain_ad {
        display: flex;
        .tu {
          position: relative;
          background-color: #e7e7e7;
          width: 140px;
          height: 55px;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }
          .tu_zi {
            font-size: 14px;
            color: #666666;
            text-align: center;
            display: block;
          }
          .tu_zi2 {
            position: absolute;
            text-align: center;
            z-index: 100;
            text-align: center;
            font-size: 14px;
            color: #ffffff;
            background-color: #1b9d97;
            display: none;
            line-height: 19px;
            height: 100%;
            width: 100%;
            padding-top: 10px;
            box-sizing: border-box;
          }
        }
        .tu:hover {
          background-color: #1b9d97;
        }
        .tu:hover .tu_zi {
          display: none;
        }
        .tu:hover .tu_zi2 {
          display: block;
        }

        .right {
          margin-left: 10px;
          .ad_font1 {
            font-size: 14px;
            color: #333333;
            margin-bottom: 6px;
          }
        }
      }
    }
    .synchronouMobileData {
      font-size: 14px;
      color: #0aa29b;
      margin-top: 14px;
      cursor: pointer;
    }
  }
  .today-work {
    display: flex;
    align-items: center;
    padding: 10px 0;
    .text {
      font-size: 14px;
      color: #333333;
      margin-left: 17px;
    }
  }
  .switch1 {
    display: flex;
    align-items: center;
    padding: 10px 0;
    .text {
      font-size: 14px;
      color: #333333;
      margin-left: 17px;
    }
  }
  .categroy {
    .show-type {
      .title {
        font-size: 14px;
        color: #333333;
        font-weight: 500;
        padding: 8px 0;
      }
      .radio1 {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }
      .el-radio {
        margin-right: 60px;
      }
      .sys {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
        margin-top: 14px;
      }
    }
  }
  .add-btn {
    height: 46px;
    margin-top: 10px;
    line-height: 46px;
    background: #f5f5f5;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    color: #0aa29b;
    &.disable {
      color: #999999;
    }
  }
  .categroy-item {
    display: flex;
    align-items: center;
    .text {
      flex: 1;
      font-size: 14px;
      color: #333333;
    }
  }
}
</style>
