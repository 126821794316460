<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-06-27 15:38:45
 * @LastEditTime: 2022-09-01 16:04:04
 * @LastEditors: cyy
 * @Description: 电子资料
 * @FilePath: \teacherdingding.com\src\views\NetSchollAdmin\pcSchoolDecoration\centerComponents\electronic.vue
-->
<template>
  <div class="course_model">
    <div class="top">
      <div class="top_font1">{{ value.name }}</div>
      <div class="top_font2" v-if="value.is_show_more == 1">
        <a href="javaScript:;">查看全部</a>
        <i class="el-icon-d-arrow-right"></i>
      </div>
    </div>
    <ul class="course-list">
      <li v-for="item in value.list" :key="item.ed_id" class="item">
        <a class="href" href="javaScript:;">
          <div class="cover">
            <img class="img" :src="item.img_url" />
          </div>
          <div class="content">
            <div class="title">
              {{ item.name }}
            </div>
            <div class="detail">
              <span class="number">
                {{ item.size }}MB | 已售{{ item.number }}
                |
                {{ item.view_num }}浏览
              </span>
            </div>
            <div class="price">
              <div class="t1" v-if="item.price > 0">￥{{ item.price }}</div>
              <div class="t1" v-else>免费</div>
              <div class="t2" v-if="item.costprice > 0">
                {{ item.costprice }}
              </div>
            </div>
          </div>
        </a>
      </li>
      <li v-for="item in 4 - (value.list.length % 4)" :key="item" class="item">
        <a class="href block" href="javaScript:;"></a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'electronic',

  props: {
    value: Object,
  },

  components: {},
}
</script>
<style lang="scss" scoped>
@import './courseItem.scss';
</style>
