<template>
  <div class="setting" :style="noMove ? 'width:225px' : ''">
    <template v-if="!noMove">
      <div
        v-if="index > 1"
        class="icon"
        title="上移一层"
        @click="move({ oldIndex: index, newIndex: index - 1 })"
      >
        <img
          src="https://f.dingdingkaike.com.cn/pcddkk/icon_syyc_hs6.svg"
          class="icon"
        />
        <div class="text">上移一层</div>
      </div>
      <div v-else class="icon" style="cursor: no-drop">
        <img
          src="https://f.dingdingkaike.com.cn/pcddkk/icon_syyc_hs6.svg"
          class="icon"
        />
        <div class="text">上移一层</div>
      </div>
      <div
        v-if="index < modulesCount - 2"
        class="icon"
        title="下移一层"
        @click="move({ oldIndex: index, newIndex: index + 1 })"
      >
        <img
          src="https://f.dingdingkaike.com.cn/pcddkk/icon_xyyc6.svg"
          class="icon"
        />
        <div class="text">下移一层</div>
      </div>
      <div v-else class="icon" style="cursor: no-drop">
        <img
          src="https://f.dingdingkaike.com.cn/pcddkk/icon_xyyc6.svg"
          class="icon"
        />
        <div class="text">下移一层</div>
      </div>
    </template>
    <div
      v-if="item.is_show == 1"
      class="icon"
      title="隐藏模块"
      @click="toggleShow({ index, status: 2 })"
    >
      <img
        src="https://f.dingdingkaike.com.cn/pcddkk/icon_ycmk11.svg"
        class="icon"
      />
      <div class="text">隐藏</div>
    </div>
    <div
      v-else
      class="icon"
      title="显示模块"
      @click="toggleShow({ index, status: 1 })"
    >
      <img
        src="https://f.dingdingkaike.com.cn/pcddkk/icon_xsmk.svg"
        class="icon"
      />
      <div class="text">显示</div>
    </div>
    <div class="icon" @click="removeFn(index)" title="删除模块">
      <img
        src="https://f.dingdingkaike.com.cn/pcddkk/icon_ycmk12.svg"
        class="icon"
      />
      <div class="text">删除</div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations } = createNamespacedHelpers('pcSchoolDecration')

export default {
  name: 'move',

  props: {
    index: Number,
    item: Object,
    noMove: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      modulesCount: state => state.modules.length,
    }),
  },

  methods: {
    ...mapMutations(['move', 'toggleShow', 'remove', 'selectedChange']),

    removeFn(index) {
      this.$confirm('是否确定删除该模块！', '删除模块', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.remove(index)
        this.selectedChange()
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.setting {
  width: 514px;
  height: 58px;
  display: flex;
  background: #fff;
  position: absolute;
  bottom: -68px;
  z-index: 11;
  border-radius: 4px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.18);
  .icon {
    box-sizing: border-box;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:first-child {
      .icon {
        margin-left: 20px;
      }
    }
    .icon {
      width: 22px;
      height: auto;
      margin: 0 0 0 19px;
    }
    .text {
      color: #333;
      font-size: 18px;
      padding: 0 19px 0 14px;
    }
  }
}
</style>
