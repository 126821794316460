<template>
  <div id="courseSetting">
    <div class="box">
      <div class="module-title">公开课</div>
      <!-- 模块标题 -->
      <div class="title">模块标题</div>
      <el-input
        type="text"
        placeholder="请输入模块标题"
        v-model="optionlist.name"
        maxlength="15"
        show-word-limit
        class="mb10"
      ></el-input>

      <!-- 展示内容 -->
      <div class="switch1">
        <el-switch
          v-model="optionlist.is_show_cover_mask"
          active-value="1"
          inactive-value="2"
        ></el-switch>
        <div class="text">只展示封面</div>
        <helpIcon
          style="margin-left: 5px"
          content="启用后，除了封面，其他元素全部隐藏"
        ></helpIcon>
      </div>

      <div class="show-type">
        <div class="title">其他信息展示</div>
        <div class="switch1">
          <el-switch
            v-model="optionlist.is_show_more"
            active-value="1"
            inactive-value="2"
          ></el-switch>
          <div class="text">查看更多</div>
        </div>
      </div>

      <div class="show-type">
        <div class="title">展示公开课</div>
        <el-radio-group v-model="optionlist.live_from" class="radio1">
          <el-radio label="1">系统默认</el-radio>
          <el-radio label="2">自定义</el-radio>
        </el-radio-group>
        <template v-if="optionlist.live_from == 1">
          <div class="sys">
            提示：系统默认显示只正在直播和未开播的公开课，已结束且未开播的公开课不显示，数据为空时，整个模块隐藏；排序根据设置的开播时间正序（从旧到新）显示
          </div>
        </template>
        <template v-else>
          <div class="mb30">
            <div class="custom">
              <div class="flex-center">
                <el-button style="width: 140px" @click="addCourse">
                  + 选择公开课
                </el-button>
                <span class="ml20 hedselect">
                  目前最多可选5个，已选 {{ optionlist.list.length }}
                </span>
              </div>
              <div
                class="course_contian"
                v-for="(i, index) in optionlist.list"
                :key="i.open_class_id"
              >
                <div class="tu">
                  <img :src="i.cover_img" alt="" />
                </div>
                <div class="right">
                  <div class="name">
                    {{ i.name }}
                  </div>
                  <div class="bottom">
                    <div class="coursetype">
                      {{
                        i.play_type == 3
                          ? `￥${i.play_price}`
                          : i.play_type == 1
                          ? '免费'
                          : '密码获取'
                      }}
                    </div>
                    <movebtn
                      :index="index"
                      :length="optionlist.list.length"
                      @upDownMove="upDownMove"
                      @remove="removeItem"
                    ></movebtn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <bottombtn @submit="submit" @cancel="selectedChange('')"></bottombtn>
  </div>
</template>

<script>
import selectdata from './selectdata'
import { mapMutations, mapState } from 'vuex'
import bottombtn from './components/bottomBtns'
import movebtn from './components/moveBtns'
import helpIcon from '@/components/helpIcon'

export default {
  name: 'workSetting',

  components: {
    bottombtn,
    movebtn,
    helpIcon,
  },

  mixins: [selectdata],

  watch: {
    // 改变指定网校内容
    singleselectcontentval(val) {
      this.optionlist.list.push(...val)
    },

    'optionlist.live_from'(val) {
      // 如果切换到初始类型时，数据进行重置
      let list = []
      // 如果切换到初始类型时，数据进行重置
      if (val == this.selectdata.live_from) {
        list = _.merge([], this.selectdata.list)
      }
      this.optionlist = _.assign({}, this.optionlist, {
        list,
      })
    },
  },

  computed: {
    ...mapState('pcSchoolDecration', ['singleselectcontentval', 'selectdata']),
  },

  methods: {
    ...mapMutations('pcSchoolDecration', [
      'selectChange',
      'selectdataChange',
      'selectedChange',
    ]),

    getCourseData(option) {
      return new Promise(resolve => {
        this.$http({
          url: '/HomeTemplate/openClassList',
          data: option,
          callback: ({ data }) => {
            resolve(data)
          },
        })
      })
    },

    // 选择公开课
    addCourse() {
      this.selectChange({
        templete: 6,
        title: '选择公开课',
        ids: this.optionlist.list.map(item => item.open_class_id),
      })
    },

    // 移动
    move(newIndex, oldIndex) {
      const item = this.optionlist.list[newIndex]
      this.optionlist.list.splice(newIndex, 1, this.optionlist.list[oldIndex])
      this.optionlist.list.splice(oldIndex, 1, item)
    },

    // 删除
    remove(index) {
      this.optionlist.list.splice(index, 1)
    },

    init() {
      // 空数据才去获取
      if (this.optionlist.list.length > 0 || this.optionlist.live_from == 2)
        return
      this.getData()
    },

    getData() {
      const val = _.merge({}, this.optionlist)
      return this.getCourseData({
        id_type: 2,
      }).then(data => {
        val.list = data
        this.selectdataChange(val)
      })
    },

    submit() {
      if (this.optionlist.list.length > 5) {
        this.$root.prompt('目前公开课只能选择5个！')
        return
      }
      this.optionlist.name = this.optionlist.name.trim()
      if (this.optionlist.live_from == 2) {
        this.success()
      } else {
        this.getData().then(() => {
          this.successPrompt()
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './courseStting.scss';
</style>
