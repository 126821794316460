<template>
  <div class="navigation">
    <a
      v-for="item in endValue"
      :key="item.id"
      class="navigation_nav"
      href="JavaScript:;"
    >
      <img :src="item.icon_url" class="icon" />
      <div class="navigation_font">{{ item.name }}</div>
    </a>
    <div class="custom-menu hidden" v-if="!isShow">
      <span class="text">
        模
        <br />
        块
        <br />
        已
        <br />
        关
        <br />
        闭
      </span>
    </div>
    <div class="custom-menu" v-if="!isShow">点击设置悬浮菜单</div>
    <!--手机选课 和 关注公众号-->
    <!-- <div class="absolute header-qrcode-confirm" style="display: none;">
        <div class="header-qrcode-content">
          <div class="jg-platform" style="display: none;">
            <div class="qrcode">
              <img src="https://img.dingdingkaike.com/pinkuo.jpg" />
            </div>
          </div>
          <div class="jg-home-page" style="display: none;">
            <div id="platform-code"></div>
            <span class="ewm-icon1"></span>
          </div>
        </div>
      </div> -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'floatMenu',

  props: {
    value: Array,
    isShow: Boolean,
  },
  computed: {
    ...mapState('pcSchoolDecration', ['selected']),

    endValue() {
      if (!this.isShow) {
        return []
      } else {
        return this.value
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.navigation {
  z-index: 10;
  padding: 25px 7px 0;
  background: #ffffff;
  position: fixed;
  right: 0;
  top: 35vh;
  border-radius: 4px;
  box-shadow: -5px 2px 12px 0px rgba(0, 0, 0, 0.09);
  a {
    text-decoration: none;
  }
  .navigation_nav {
    display: block;
    position: relative;
    text-align: center;
    font-size: 12px;
    color: #999999;
    margin-bottom: 36px;
    .icon {
      width: 20px;
      height: 20px;
      margin-left: 24px;
      object-fit: contain;
    }

    .navigation_font {
      width: 67px;
      margin-top: 8px;
    }
    &:last-child {
      margin-bottom: 25px;
    }
  }
  .custom-menu {
    width: 18px;
    font-size: 18px;
    color: #333;
    line-height: 1.3;
    text-align: center;
    padding: 0 10px 25px;
    cursor: pointer;
    &.hidden {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      color: #fff;
      padding: 0;
      box-sizing: border-box;
      background: rgba(0, 0, 0, 0.69);
      .text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
