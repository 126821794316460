<template>
  <div class="teacher">
    <div class="title">{{ value.name }}</div>
    <template v-if="newList.length > 0">
      <el-carousel
        class="my-swipe"
        :autoplay="false"
        v-if="value.show_way == 1 && reset"
        :indicator-position="newList.length > 1 ? 'outside' : 'none'"
        :arrow="newList.length > 1 ? 'hover' : 'never'"
      >
        <el-carousel-item v-for="(val, index) in newList" :key="index">
          <div class="teacher-info">
            <div
              class="item"
              v-for="item in val"
              :key="item.pc_team_component_teacher_id"
            >
              <div class="left">
                <img class="photo" :src="item.uphoto" alt="" />
                <div class="uname">{{ item.uname }}</div>
              </div>
              <div class="right">
                <div class="info">
                  {{
                    item.ujianjie || '这位老师很神秘且低调，什么都没有留下！'
                  }}
                </div>
                <div v-if="item.video_url" class="video-info">
                  <span class="icon" @click="preViewVideo(item)"></span>
                  <span class="text" @click="preViewVideo(item)">视频介绍</span>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <template v-else>
        <div class="teacher-info2">
          <div
            class="item"
            v-for="item in newList"
            :key="item.pc_team_component_teacher_id"
          >
            <img class="photo" :src="item.uphoto" alt="" />
            <div class="name">{{ item.uname }}</div>
            <div class="info">
              {{ item.ujianjie || '这位老师很神秘且低调，什么都没有留下！' }}
            </div>
            <div v-if="item.video_url" class="video-info">
              <span class="icon" @click="preViewVideo(item)"></span>
              <span class="text" @click="preViewVideo(item)">视频介绍</span>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
export default {
  name: 'teacher',

  props: {
    value: Object,
  },

  components: {},

  data() {
    return {
      reset: true,
      previewSrc: '',
    }
  },

  watch: {
    newList() {
      this.reset = false
      this.reset = true
    },
  },

  computed: {
    newList() {
      if (this.value.show_way == 1) {
        return _.chunk(this.value.list, 2)
      } else {
        return this.value.list
      }
    },
  },

  methods: {
    // 预览视频
    preViewVideo(item) {
      this.$emit('preViewVideo', item)
    },
  },
}
</script>
<style lang="scss" scoped>
.teacher {
  .title {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    text-align: center;
  }
  ::v-deep .my-swipe {
    padding-top: 30px;
    margin-bottom: 28px;
    .el-carousel__button {
      width: 6px;
      height: 6px;
      opacity: 1;
      background: #a5a5a5;
      border-radius: 3px;
    }
    .el-carousel__indicator--horizontal {
      padding: 12px 7px;
    }
    .el-carousel__indicator.is-active .el-carousel__button {
      background: rgba(61, 128, 239, 1);
    }
  }
  .teacher-info {
    padding: 0 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      border: 1px solid #f4f4f4;
      height: 100%;
      padding: 20px;
      box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 3%);
      flex: 1;
      display: flex;
      max-width: 50%;
      align-items: center;
      box-sizing: border-box;
      margin-right: 20px;
      .left {
        .photo {
          width: 94px;
          height: 94px;
          margin: 0 auto;
          display: block;
          border-radius: 50%;
        }
        .uname {
          font-size: 14px;
          font-weight: bold;
          color: #333333;
          margin-top: 20px;
          text-align: center;
        }
      }
      .right {
        margin-left: 40px;
        .info {
          font-size: 13px;
          color: #333333;
          line-height: 22px;
          word-break: break-all;
          max-height: 176px;
          overflow: hidden;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 10px;
          }
          &::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 15px;
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
            background: #9b9b9b;
          }

          &::-webkit-scrollbar-track {
            border: 0;
            background: none;
          }
        }
        .video-info {
          display: flex;
          align-items: center;
          margin-top: 20px;
          .icon {
            width: 14px;
            height: 15px;
            margin-right: 10px;
            background: url('https://f.dingdingkaike.com.cn/pcddkk/icon_pczx_bf.svg')
              no-repeat center;
            background-size: 100%;
          }
          .text {
            font-size: 14px;
            color: #3d80ef;
            cursor: pointer;
          }
        }
      }
    }
  }
  .teacher-info2 {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .item {
      border: 1px solid #f4f4f4;
      margin-top: 30px;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.05);
      padding: 10px;
      width: calc(25% - 20px);
      display: flex;
      flex-flow: column;
      border-radius: 2px;
      text-align: center;
      box-sizing: border-box;
      padding: 40px 20px 20px 20px;
      border-bottom: 1px solid #f4f4f4;
      margin-right: 20px;
      box-sizing: border-box;
      &:nth-child(4n) {
        width: 25%;
        margin-right: 0;
      }
      .photo {
        width: 80px;
        height: 80px;
        margin: 0 auto;
        display: block;
        border-radius: 6px;
      }
      .name {
        color: #333;
        font-size: 19px;
        margin-top: 20px;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .info {
        flex: 1;
        font-size: 12px;
        color: #141212;
        line-height: 20px;
        word-break: break-all;
        margin-top: 20px;
        text-align: left;
      }
      .video-info {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          width: 14px;
          height: 15px;
          margin-right: 10px;
          background: url('https://f.dingdingkaike.com.cn/pcddkk/icon_pczx_bf.svg')
            no-repeat center;
          background-size: 100%;
          display: inline-block;
        }
        .text {
          font-size: 14px;
          color: #3d80ef;
          cursor: pointer;
          display: inline-block;
        }
      }
    }
  }
}
</style>
