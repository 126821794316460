<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-05-13 11:41:56
 * @LastEditTime: 2022-05-20 17:28:53
 * @LastEditors: cyy
 * @Description: 导航管理
 * @FilePath: \teacher.dingdingkaike.com\src\views\NetSchollAdmin\pcSchoolDecoration\editComponents\components\navigationManagement.vue
-->
<template>
  <div id="navigationManagement">
    <el-dialog
      title="管理导航"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :append-to-body="true"
      :close-on-click-modal="false"
      :destroy-on-close="false"
      @close="cancel()"
    >
      <div slot="title" class="relative">
        管理导航
        <span class="redcolor ml10 fs12" v-if="isSort">
          提示：排序只对同级导航有效，不支持跨级排序
        </span>
        <i class="el-icon-close " @click="sortCancel" v-if="isSort"></i>
      </div>
      <div class="dialogVisible_bottom">
        <div class="header" v-if="!isSort">
          <div class="left">
            <el-button
              type="primary"
              @click="addNav"
              :disabled="datalist.length == 6"
            >
              添加一级导航
            </el-button>
            <span class="number">一级导航数量：{{ datalist.length }}/6</span>
          </div>
          <div class="right">
            <el-button type="text" style="font-weight: bold;" @click="sort">
              导航排序
            </el-button>
          </div>
        </div>
        <template>
          <!-- 列表 -->
          <div class="table">
            <div class="t_header">
              <div class="cel1">导航名称</div>
              <div class="cel2" v-if="!isSort">跳转链接</div>
              <div class="cel3">操作</div>
            </div>
            <div
              class="contain rank1"
              v-for="(item, index) in datalist"
              :key="item.id"
            >
              <div class="row1">
                <div class="col1">
                  <i
                    v-if="item.children.length > 0"
                    class="el-icon-arrow-right i1"
                    @click="openClose(item.id)"
                    :style="
                      selectID.indexOf(item.id) > -1
                        ? 'transform: rotate(90deg)'
                        : ''
                    "
                  ></i>
                  <div style="width:20px" v-else></div>
                  <template v-if="!item.edit">
                    <div class="col1_name cp" @click="openClose(item.id)">
                      {{ item.category_name }}
                    </div>
                    <i
                      v-if="!isSort"
                      class="el-icon-edit i2"
                      @click="editName(item, [index])"
                    ></i>
                  </template>
                  <template v-else>
                    <el-input
                      size="medium"
                      placeholder="请输入导航名称"
                      show-word-limit
                      maxlength="10"
                      style="width:205px"
                      @input="itemInput"
                      @blur="itemBlur($event, item)"
                      :value="item.category_name"
                      :ref="`inputRef${item.id}`"
                    ></el-input>
                  </template>
                </div>
                <div class="col2" v-if="!isSort">
                  <select-link
                    :id="item.id"
                    :key="item.id"
                    :index="index"
                    :value="item"
                    @compelet="compelet"
                  ></select-link>
                  <div class="flex-center t_label_contain">
                    <div
                      class="t_label name_label"
                      :title="item.link"
                      v-if="item.default_type == 5"
                    >
                      {{ item.link }}
                    </div>
                    <div
                      class="t_label name_label"
                      :title="item.url_name"
                      v-else
                    >
                      {{ item.url_name }}
                    </div>
                  </div>
                </div>
                <div class="col3" v-if="!isSort">
                  <el-button
                    type="text"
                    @click="addNav2([index], item.id)"
                    :disabled="item.children.length == 10"
                  >
                    添加二级导航
                  </el-button>
                  <span class="driver">|</span>
                  <el-button type="text" @click="del(item, [index])">
                    删除
                  </el-button>
                </div>
                <div class="col3" v-else>
                  <el-button
                    type="text"
                    :disabled="index == 0"
                    @click="upDownMove([index], index - 1)"
                  >
                    上移
                  </el-button>
                  <span class="driver">|</span>
                  <el-button
                    type="text"
                    :disabled="index == datalist.length - 1"
                    @click="upDownMove([index], index + 1)"
                  >
                    下移
                  </el-button>
                  <span class="driver">|</span>
                  <el-button
                    type="text"
                    @click="topBottomMove([index], 1)"
                    :disabled="index == 0"
                  >
                    置顶
                  </el-button>
                  <span class="driver">|</span>
                  <el-button
                    type="text"
                    @click="topBottomMove([index], 2)"
                    :disabled="index == datalist.length - 1"
                  >
                    置底
                  </el-button>
                </div>
              </div>

              <template v-if="selectID.indexOf(item.id) > -1">
                <div v-for="(item2, index2) in item.children" :key="item2.id">
                  <div class="row2">
                    <div class="col1" style="flex:39%">
                      <i
                        v-if="item2.children.length > 0"
                        class="el-icon-arrow-right i1"
                        @click="openClose2(item2.id)"
                        :style="
                          selectID2.indexOf(item2.id) > -1
                            ? 'transform: rotate(90deg)'
                            : ''
                        "
                      ></i>
                      <div style="width:20px" v-else></div>
                      <template v-if="!item2.edit">
                        <div
                          class="col1_name cp"
                          @click="openClose2(item2.id)"
                          style="font-weight: normal"
                        >
                          {{ item2.category_name }}
                        </div>
                        <i
                          v-if="!isSort"
                          class="el-icon-edit i2 "
                          @click="editName(item2, [index, index2])"
                        ></i>
                      </template>
                      <template v-else>
                        <el-input
                          placeholder="请输入导航名称"
                          show-word-limit
                          size="medium"
                          maxlength="10"
                          style="width:205px"
                          @input="itemInput"
                          @blur="itemBlur($event, item2)"
                          :value="item2.category_name"
                          :ref="`inputRef${item2.id}`"
                        ></el-input>
                      </template>
                    </div>
                    <div class="col2" v-if="!isSort">
                      <select-link
                        :id="item2.id"
                        :key="item2.id"
                        :index="index2"
                        :value="item2"
                        :fatherIndex="[index]"
                        @compelet="compelet2"
                      ></select-link>
                      <div class="flex-center t_label_contain">
                        <div
                          class="t_label name_label"
                          :title="item2.link"
                          v-if="item2.default_type == 5"
                        >
                          {{ item2.link }}
                        </div>
                        <div
                          class="t_label name_label"
                          :title="item2.url_name"
                          v-else
                        >
                          {{ item2.url_name }}
                        </div>
                      </div>
                    </div>
                    <div class="col3" v-if="!isSort">
                      <el-button
                        type="text"
                        @click="addNav3([index, index2], item2.id)"
                        :disabled="item2.children.length == 6"
                      >
                        添加三级导航
                      </el-button>
                      <span class="driver">|</span>
                      <el-button
                        type="text"
                        @click="del(item2, [index, index2])"
                      >
                        删除
                      </el-button>
                    </div>
                    <div class="col3" v-else>
                      <el-button
                        type="text"
                        :disabled="index2 == 0"
                        @click="upDownMove([index, index2], index2 - 1)"
                      >
                        上移
                      </el-button>
                      <span class="driver">|</span>
                      <el-button
                        type="text"
                        :disabled="index2 == item.children.length - 1"
                        @click="upDownMove([index, index2], index2 + 1)"
                      >
                        下移
                      </el-button>
                      <span class="driver">|</span>
                      <el-button
                        type="text"
                        @click="topBottomMove([index, index2], 1)"
                        :disabled="index2 == 0"
                      >
                        置顶
                      </el-button>
                      <span class="driver">|</span>
                      <el-button
                        type="text"
                        @click="topBottomMove([index, index2], 2)"
                        :disabled="index2 == item.children.length - 1"
                      >
                        置底
                      </el-button>
                    </div>
                  </div>
                  <template v-if="selectID2.indexOf(item2.id) > -1">
                    <div
                      class="row3"
                      v-for="(item3, index3) in item2.children"
                      :key="item3.id"
                    >
                      <div class="col1" style="flex:36%">
                        <template v-if="!item3.edit">
                          <div class="col1_name" style="font-weight: normal">
                            {{ item3.category_name }}
                          </div>
                          <i
                            v-if="!isSort"
                            class="el-icon-edit i2"
                            @click="editName(item3, [index, index2, index3])"
                          ></i>
                        </template>
                        <template v-else>
                          <el-input
                            placeholder="请输入导航名称"
                            show-word-limit
                            maxlength="10"
                            size="medium"
                            style="width:205px"
                            :value="item3.category_name"
                            @input="itemInput"
                            @blur="itemBlur($event, item3)"
                            :ref="`inputRef${item3.id}`"
                          ></el-input>
                        </template>
                      </div>
                      <div class="col2" v-if="!isSort">
                        <select-link
                          :id="item3.id"
                          :key="item3.id"
                          :index="index3"
                          :value="item3"
                          :fatherIndex="[index, index2]"
                          @compelet="compelet3"
                        ></select-link>
                        <div class="flex-center t_label_contain">
                          <div
                            class="t_label name_label"
                            :title="item3.link"
                            v-if="item3.default_type == 5"
                          >
                            {{ item3.link }}
                          </div>
                          <div
                            class="t_label name_label"
                            :title="item3.url_nam"
                            v-else
                          >
                            {{ item3.url_name }}
                          </div>
                        </div>
                      </div>
                      <div class="col3" v-if="!isSort">
                        <!-- <el-button type="text">
                      +添加子导航
                    </el-button>
                    <span class="driver">|</span> -->
                        <el-button
                          type="text"
                          @click="del(item3, [index, index2, index3])"
                        >
                          删除
                        </el-button>
                      </div>
                      <div class="col3" v-else>
                        <el-button
                          type="text"
                          :disabled="index3 == 0"
                          @click="
                            upDownMove([index, index2, index3], index3 - 1)
                          "
                        >
                          上移
                        </el-button>
                        <span class="driver">|</span>
                        <el-button
                          type="text"
                          :disabled="index3 == item2.children.length - 1"
                          @click="
                            upDownMove([index, index2, index3], index3 + 1)
                          "
                        >
                          下移
                        </el-button>
                        <span class="driver">|</span>
                        <el-button
                          type="text"
                          @click="topBottomMove([index, index2, index3], 1)"
                          :disabled="index3 == 0"
                        >
                          置顶
                        </el-button>
                        <span class="driver">|</span>
                        <el-button
                          type="text"
                          :disabled="index3 == item2.children.length - 1"
                          @click="topBottomMove([index, index2, index3], 2)"
                        >
                          置底
                        </el-button>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
      <span slot="footer" class="dialog-footer" v-if="!isSort">
        <el-button @click="cancel" size="medium">取 消</el-button>
        <el-button type="primary" @click="submit" size="medium">
          确 定
        </el-button>
      </span>

      <span slot="footer" class="dialog-footer" v-else>
        <div class="_center">
          <el-button @click="sortCancel" style="width:90px" size="medium">
            取消
          </el-button>
          <el-button
            type="primary"
            @click="submitSort"
            style="width:90px"
            size="medium"
          >
            保存排序
          </el-button>
        </div>
      </span>
    </el-dialog>

    <!-- 新建导航 -->
    <el-dialog
      :title="isedit ? '编辑导航' : '添加导航'"
      :visible.sync="newNav"
      width="422px"
      style="height:300px"
      :modal-append-to-body="true"
      :close-on-click-modal="false"
      :append-to-body="true"
      @close="cancelNewnav"
    >
      <el-input
        placeholder="请输入导航名称"
        show-word-limit
        maxlength="10"
        v-model="newlist.category_name"
      ></el-input>
      <span slot="footer">
        <el-button @click="newNav = false">取 消</el-button>
        <el-button
          type="primary"
          @click="
            isedit
              ? edit()
              : addIndexLength == 1
              ? addNav2(addIndex)
              : addIndexLength == 2
              ? addNav3(addIndex)
              : addNav()
          "
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
    <!-- 导航管理 -->
    <navsort
      v-if="status"
      :dialogstatus.sync="status"
      :sortlist="sortlist"
    ></navsort>
  </div>
</template>

<script>
import navsort from './navsort'
import selectLink from '@cm/selectLink'
export default {
  name: 'navigationManagement',
  data() {
    return {
      endVal: [],
      copyDatalist: [],
      isSort: false,
      status: false,
      selectID: [],
      selectID2: [],
      addIndexLength: 0,
      addIndex: [],
      newNav: false,
      isedit: false,
      dialogVisible: false,
      newlist: {
        id: Math.random(),
        category_name: '',
        set_id: '',
        commodity_type: '',
        default_type: '',
        link: '',
        edit: false,
        children: this.addIndexLength != 2 ? [] : undefined,
      },
    }
  },

  components: {
    selectLink,
    navsort,
  },

  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },
    category: [Array],
  },

  created() {
    this.datalist = _.merge([], this.category)
  },

  methods: {
    cancelNewnav() {
      this.isedit = false
    },

    // 置顶 置底
    topBottomMove(index, type) {
      switch (index.length) {
        case 1:
          this.oldData = this.datalist.splice(index[0], 1)[0]
          if (type == 1) {
            this.datalist.unshift(this.oldData)
          } else {
            this.datalist.push(this.oldData)
          }
          break
        case 2:
          this.oldData = this.datalist[index[0]].children.splice(index[1], 1)[0]
          if (type == 1) {
            this.datalist[index[0]].children.unshift(this.oldData)
          } else {
            this.datalist[index[0]].children.push(this.oldData)
          }
          break
        case 3:
          this.oldData = this.datalist[index[0]].children[
            index[1]
          ].children.splice(index[2], 1)[0]
          if (type == 1) {
            this.datalist[index[0]].children[index[1]].children.unshift(
              this.oldData
            )
          } else {
            this.datalist[index[0]].children[index[1]].children.push(
              this.oldData
            )
          }
          break
      }
      this.$forceUpdate()
    },

    // 上下移动
    upDownMove(index, newIndex) {
      switch (index.length) {
        case 1:
          this.oldindex = index[0]
          this.oldData = this.datalist[newIndex]
          this.datalist.splice(newIndex, 1, this.datalist[this.oldindex])
          this.datalist.splice(this.oldindex, 1, this.oldData)
          break
        case 2:
          this.oldindex = index[1]
          this.oldData = this.datalist[index[0]].children[newIndex]
          this.datalist[index[0]].children.splice(
            newIndex,
            1,
            this.datalist[index[0]].children[this.oldindex]
          )
          this.datalist[index[0]].children.splice(
            this.oldindex,
            1,
            this.oldData
          )
          break
        case 3:
          this.oldindex = index[2]
          this.oldData = this.datalist[index[0]].children[index[1]].children[
            newIndex
          ]

          this.datalist[index[0]].children[index[1]].children.splice(
            newIndex,
            1,
            this.datalist[index[0]].children[index[1]].children[this.oldindex]
          )

          this.datalist[index[0]].children[index[1]].children.splice(
            this.oldindex,
            1,
            this.oldData
          )
          break
      }
      this.$forceUpdate()
    },

    submitSort() {
      this.isSort = false
    },

    sortCancel() {
      this.isSort = false
      this.datalist = _.merge([], this.copyDatalist)
    },

    sort() {
      this.copyDatalist = _.merge([], this.datalist)
      this.isSort = true
      // this.sortlist = this.datalist
      // this.status = true
    },
    // 删除
    del(item, indexlist) {
      if (indexlist.length != 3) {
        if (item.children.length > 0) {
          this.$confirm('删除后，该级下面的所有子级都会一起删掉', '删除导航', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          })
            .then(() => {
              this.getval(indexlist)
            })
            .catch(() => {})
        } else {
          this.getval(indexlist)
        }
      } else {
        this.datalist[indexlist[0]].children[indexlist[1]].children.splice(
          indexlist[2],
          1
        )
      }
      this.$forceUpdate()
    },

    getval(indexlist) {
      const val = indexlist.length
      switch (Number(val)) {
        case 1:
          this.datalist.splice(indexlist[0], 1)
          break
        case 2:
          this.datalist[indexlist[0]].children.splice(indexlist[1], 1)
          break
      }
    },

    openClose(id) {
      const index = this.selectID.indexOf(id)
      if (index == -1) {
        this.selectID.push(id)
      } else {
        this.selectID.splice(index, 1)
      }
    },

    openClose2(id) {
      const index = this.selectID2.indexOf(id)
      if (index == -1) {
        this.selectID2.push(id)
      } else {
        this.selectID2.splice(index, 1)
      }

      // if (!this.selectID2) {
      //   this.selectID2 = id
      // } else if (this.selectID2 != id) {
      //   this.selectID2 = id
      // } else {
      //   this.selectID2 = ''
      // }
    },

    newNavclick(index, id) {
      this.id = id
      this.addIndexLength = (index && index.length) || ''
      this.addIndex = index
      this.resetNewlist()
      this.newNav = true
    },

    // 编辑
    itemBlur(event, val) {
      if (!val.category_name.trim()) {
        this.$root.prompt('请先输入导航名称！')
        this.$nextTick(() => {
          // document.querySelector(`.inputRef${item.id} input`).focus()
          this.$refs[`inputRef${val.id}`][0].focus()
        })
        return
      }

      this.getindexVal(this.editIndex.length, false, '')
      this.resetNewlist()
    },

    getindexVal(val, edit, text) {
      this.newlist = edit
        ? _.merge({}, this.newlist, { category_name: text })
        : this.newlist
      switch (Number(val)) {
        case 1:
          this.datalist[this.editIndex[0]] = _.merge({}, this.newlist, {
            edit: edit,
            category_name: edit ? text : this.newlist.category_name,
          })
          break
        case 2:
          this.datalist[this.editIndex[0]].children[
            this.editIndex[1]
          ] = _.merge({}, this.newlist, {
            edit: edit,
            category_name: edit ? text : this.newlist.category_name,
          })
          break
        case 3:
          this.datalist[this.editIndex[0]].children[this.editIndex[1]].children[
            this.editIndex[2]
          ] = _.merge({}, this.newlist, {
            edit: edit,
            category_name: edit ? text : this.newlist.category_name,
          })
          break
      }

      this.$forceUpdate()
    },

    // 编辑名称输入框聚焦
    itemInput(val) {
      this.getindexVal(this.editIndex.length, true, val)
    },

    // 编辑名称
    editName(item, indexlist) {
      this.editIndex = indexlist
      this.newlist = _.merge({}, item)
      this.getindexVal(this.editIndex.length, true)

      this.$nextTick(() => {
        // document.querySelector(`.inputRef${item.id} input`).focus()
        this.$refs[`inputRef${item.id}`][0].focus()
      })
    },

    // 重置新建数据
    resetNewlist() {
      this.newNav = false
      this.newlist = {
        id: Math.random(),
        category_name: '',
        set_id: '',
        commodity_type: '',
        default_type: '',
        link: '',
        children: this.addIndexLength != 2 ? [] : undefined,
      }
    },

    // 添加二级导航
    addNav2(index, id) {
      // if (!this.newlist.category_name.trim()) {
      //   this.$root.prompt('请先输入导航名称！')
      //   return
      // }
      if (this.selectID.indexOf(id) == -1) {
        this.selectID.push(id)
      }
      this.datalist[index[0]].children.push(
        _.assign({}, this.newlist, { category_name: '二级导航' })
      )
      this.resetNewlist()
    },

    // 添加三级导航
    addNav3(index, id) {
      // if (!this.newlist.category_name.trim()) {
      //   this.$root.prompt('请先输入导航名称！')
      //   return
      // }
      if (this.selectID2.indexOf(id) == -1) {
        this.selectID2.push(id)
      }
      this.datalist[index[0]].children[index[1]].children.push(
        _.assign({}, this.newlist, { category_name: '三级导航' })
      )

      this.resetNewlist()
    },

    // 添加一级导航
    addNav() {
      // if (!this.newlist.category_name.trim()) {
      //   this.$root.prompt('请先输入导航名称！')
      //   return
      // }
      this.datalist.push(
        _.assign({}, this.newlist, { category_name: '一级导航' })
      )
      this.resetNewlist()
    },

    // 第一级
    compelet({ val, index }) {
      const obj = _.merge({}, val)
      this.datalist[index] = obj
      this.$forceUpdate()
    },

    // 第二级
    compelet2({ val, index, fatherIndex }) {
      const obj = _.merge({}, val)
      this.datalist[fatherIndex[0]].children[index] = obj
      this.$forceUpdate()
    },

    // 第三级
    compelet3({ val, index, fatherIndex }) {
      const obj = _.merge({}, val)
      this.datalist[fatherIndex[0]].children[fatherIndex[1]].children[
        index
      ] = obj
      this.$forceUpdate()
    },

    setDisabled(val) {
      for (var i = 0; i < val.length; i++) {
        if (val[i].default_type) {
          if (val[i].children && val[i].children.length > 0) {
            this.setDisabled(val[i].children)
          } else {
            this.endVal.splice(0, 1)
          }
        } else {
          this.endVal.push('11')
        }
      }
    },

    submit() {
      this.setDisabled(this.datalist)
      if (this.endVal.length > 0) {
        this.$root.prompt('请先为导航名称选择跳转链接！')
        return
      }
      this.$emit('complete', this.datalist)
      this.$emit('update:dialogstatus', false)
    },
    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.t_label_contain {
  width: 142px;
  .t_label {
    width: 142px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .el-icon-close {
    cursor: pointer;
    font-size: 14px;
    padding: 10px;
    display: none;
  }
  &:hover .el-icon-close {
    display: block;
  }
}

::v-deep .dialog-footer {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
::v-deep .el-dialog__body {
  padding: 0 20px;
}
::v-deep .el-dialog {
  min-height: 500px;
}

.rank1 {
  &:hover {
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  }
}
.flip-list-move {
  transition: transform 0.35s;
}
.el-icon-close {
  color: #bfc1c5;
  cursor: pointer;
  z-index: 1;
  font-size: 16px;
  position: absolute;
  right: 0px;
  font-weight: 100;
  &:hover {
    color: #0aa29b;
  }
}
.dialogVisible_bottom {
  padding-bottom: 40px;
  .header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .number {
        margin-left: 20px;
        font-size: 14px;
        color: #333333;
        margin-left: 20px;
      }
    }
  }
  .table {
    .t_header {
      padding: 0 20px;
      height: 42px;
      background: #f5f5f5;
      border-radius: 1px;
      display: flex;
      align-items: center;
      .cel1,
      .cel2,
      .cel3 {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }
      .cel1 {
        flex: 40%;
      }
      .cel2 {
        flex: 40%;
      }
      .cel3 {
        text-align: right;
        flex: 20%;
      }
    }
    .contain {
      border-bottom: 1px solid rgba(245, 245, 245, 1);
    }
    .row1 {
      padding: 0 20px 0 10px;
      display: flex;
      align-items: center;
      height: 50px;
    }
    .col1 {
      flex: 40%;
      display: flex;
      align-items: center;
      .col1_name {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        margin: 0 10px 0 0;
      }
      .i1 {
        padding: 5px;
        cursor: pointer;
        font-size: 12px;
      }
      .i2 {
        cursor: pointer;
        font-style: 12px;
        color: rgba(10, 162, 155, 1);
      }
    }
    .col2 {
      display: flex;
      flex: 40%;
    }
    .col3 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 20%;
      .driver {
        color: rgba(10, 162, 155, 1);
        margin: 0 5px;
      }
    }
    .row3 {
      padding: 0 20px 0 45px;
      display: flex;
      align-items: center;
    }
    .row2 {
      padding: 0 20px 0 20px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
