var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"livebroadcastPreview1",style:(_vm.value.is_show == 2 ? 'border:0' : '')},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"flex-center"},[_c('img',{attrs:{"src":require("@ass/img/1.4.9/icon_pczx_zbyg.svg"),"alt":""}}),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.value.name)+"("+_vm._s(_vm.value.list && _vm.value.list.length)+") ")])]),_c('i',{staticClass:"el-icon-close cp"})]),(_vm.value.list.length > 0)?_c('div',{staticClass:"main_list"},_vm._l((_vm.value.list),function(item){return _c('div',{key:item.key,staticClass:"item"},[_c('div',{staticClass:"content",style:(item.type != 3 ? 'border-bottom: 1px solid #E8E8E8;' : '')},[_c('div',{staticClass:"tu"},[_c('img',{attrs:{"src":item.photo,"alt":""}})]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"top"},[(_vm.value.course_type_hide == 1)?_c('div',{staticClass:"type",style:(item.type == 1
                    ? 'background: #3d80ef'
                    : item.type == 2
                    ? 'background: #997EE1'
                    : 'background: #FE7B4B')},[_vm._v(" "+_vm._s(_vm._f("gettype")(item.type))+" ")]):_vm._e(),_c('div',{staticClass:"name",attrs:{"title":item.title}},[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"time"},[_vm._v(_vm._s(item.star_time)+"开播")]),_c('div',{staticClass:"btn"},[(
                    item.is_taday_tomorrow == 1 || item.is_taday_tomorrow == 2
                  )?[(item.islive == 1)?_c('el-button',{staticStyle:{"width":"80px"},attrs:{"type":"danger","size":"small"}},[_vm._v(" 正在直播 ")]):_c('el-button',{staticStyle:{"width":"80px"},attrs:{"type":"primary","size":"small"}},[_vm._v(" 即将开播 ")])]:_vm._e()],2)])])]),(item.type != 3)?_c('div',{staticClass:"coursename"},[_vm._v(_vm._s(item.name))]):_vm._e()])}),0):_c('div',{staticClass:"empty"},[_c('div',[_vm._v("暂无预告数据")]),_c('div',{staticClass:"mt10"},[_vm._v(" 暂无直播预告，网校将不显示预告弹窗 ")])]),(_vm.value.is_show == 2)?_c('hidden'):_vm._e(),(_vm.selected == _vm.value.id)?_c('move',{attrs:{"item":_vm.value,"index":_vm.index,"noMove":true}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }