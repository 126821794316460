<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-05-11 16:06:26
 * @LastEditTime: 2022-09-01 16:10:22
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\NetSchollAdmin\pcSchoolDecoration\components\configure.vue
-->
<template>
  <div id="configure">
    <component class="contain" :is="componentName"></component>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// 课程参数设置
import courseSetting from '../editComponents/courseSetting'
//悬浮按钮
import floatMenu from '../editComponents/floatMenu'
//师资团队
import facultyTeam from '../editComponents/facultyTeam'
// 广告栏
import banner from '../editComponents/banner'
//顶部
import myHeader from '../editComponents/Header'
//作业
import openClass from '../editComponents/openClass'
// 直播预告
import livebroadcastPreview from '../editComponents/livebroadcastPreview'
//电子资料
import electronicSetting from '../editComponents/electronicSetting'
// 富文本
import richtext from '../editComponents/richtext'
// 图文
import graphic from '../editComponents/graphic'
// import pageOverflow from '@/mixin/pageOverflow'

export default {
  components: {
    banner,
    facultyTeam,
    floatMenu,
    courseSetting,
    myHeader,
    openClass,
    electronicSetting,
    livebroadcastPreview,
    richtext,
    graphic,
  },

  // mixins: [pageOverflow],

  computed: {
    ...mapState('pcSchoolDecration', ['selectdata']),

    componentName() {
      let name
      switch (Number(this.selectdata.data_type)) {
        case -1:
          name = 'myHeader'
          break
        case -2:
          name = 'floatMenu'
          break
        case 1:
          name = 'banner'
          break
        case 2:
          name = 'courseSetting'
          break
        case 3:
          name = 'openClass'
          break
        case 4:
          name = 'electronicSetting'
          break
        case 5:
          name = 'facultyTeam'
          break
        case 6:
        case 8:
          name = 'livebroadcastPreview'
          break
        case 7:
          name = 'richtext'
          break
        case 9:
          name = 'graphic'
          break
        default:
          name = ''
      }
      return name
    },
  },
}
</script>

<style lang="scss" scoped>
#configure {
  overflow: hidden;
  height: 100%;
  position: relative;
  box-shadow: -6px 0px 10px 0px rgba(0, 0, 0, 0.12);
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: #fff;
  }
  .contain {
    padding: 20px;
  }
}
</style>
