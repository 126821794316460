<template>
  <div class="move-btns">
    <div class="vm item">
      <el-button
        type="text"
        @click="upDownMove(index, index - 1)"
        :disabled="index == 0"
      >
        上移
      </el-button>
    </div>
    <div class="vm division"></div>
    <div class="vm item">
      <el-button
        type="text"
        :disabled="index == length - 1"
        @click="upDownMove(index, index + 1)"
      >
        下移
      </el-button>
    </div>
    <div class="vm division"></div>
    <div class="vm item">
      <el-popconfirm
        :title="popconfirm"
        v-if="popconfirm"
        @confirm="remove(index)"
      >
        <el-button slot="reference" type="text">
          {{ removeText }}
        </el-button>
      </el-popconfirm>
      <el-button v-else slot="reference" @click="remove(index)" type="text">
        {{ removeText }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'move-btns',

  props: {
    index: Number,
    length: Number,
    popconfirm: String,
    removeText: {
      type: String,
      default: '移除',
    },
  },

  methods: {
    upDownMove() {
      this.$emit('upDownMove', ...arguments)
    },

    remove() {
      this.$emit('remove', ...arguments)
    },
  },
}
</script>

<style lang="scss" scoped>
.move-btns {
  font-size: 0;
  color: #1b9d97;
  white-space: nowrap;
  .item {
    width: 50px;
    text-align: center;
    cursor: pointer;
  }
  .division {
    width: 1px;
    height: 12px;
    background-color: #1b9d97;
  }
  .el-button {
    font-size: 12px;
  }
}
</style>
