<template>
  <!-- 网校装修首页 -->
  <div id="onlineSchoolDecoration">
    <!-- 顶部 -->
    <head-navigation></head-navigation>
    <div class="main_shcool">
      <!-- 左侧模块添加区域 -->
      <div class="left" :class="{ transFormHidden: !isshow }">
        <modular :isshow="isshow"></modular>
        <div class="close" @click="closeopen" v-if="isshow">
          <i class="el-icon-close"></i>
        </div>
        <div class="open" @click="closeopen" v-else>
          添
          <br />
          加
          <br />
          模
          <br />
          块
        </div>
      </div>
      <!-- 中间网校首页效果展示区域 -->
      <div class="net_shool_contain" ref="net_shool_contain">
        <div class="net_school_show" ref="contBox" :style="transformStyle">
          <div
            v-for="(item, index) in modules"
            :key="item.id"
            @click="item.data_type != -1 ? isSelected(item.id) : ''"
            :class="{
              moduleSelect:
                (item.data_type == 6 &&
                  item.is_center == 1 &&
                  selected == item.id) ||
                (item.data_type != 6 &&
                  item.data_type != -2 &&
                  selected == item.id),
              borderstyle:
                (item.data_type == 6 && item.is_center == 1) ||
                (item.data_type != 6 &&
                  item.data_type != -2 &&
                  item.data_type != -1),
              maskBorder: item.is_show == 2,
            }"
            class="module lr-mr"
          >
            <myheader :value="item.list" v-if="item.data_type == -1"></myheader>
            <floatMenu
              class="borderstyle"
              :class="{
                moduleSelect: selected == item.id,
                maskBorder: item.is_show == 2,
                ['module' + item.data_type]: true,
              }"
              :value="item.list"
              :isShow="item.status == 1"
              v-if="item.data_type == -2"
            ></floatMenu>
            <banner :value="item" v-else-if="item.data_type == 1"></banner>
            <openClass
              :value="item"
              v-else-if="item.data_type == 3"
            ></openClass>
            <courseItem
              :value="item"
              v-else-if="item.data_type == 2"
            ></courseItem>
            <electronic
              :value="item"
              v-else-if="item.data_type == 4"
            ></electronic>
            <teacher
              :value="item"
              @preViewVideo="preViewVideo"
              v-else-if="item.data_type == 5"
            ></teacher>

            <livebroadcast-preview1
              :value="item"
              class="borderstyle"
              :index="index"
              :class="{
                moduleSelect: selected == item.id,
                maskBorder: item.is_show == 2,
              }"
              v-if="item.data_type == 6"
            ></livebroadcast-preview1>

            <livebroadcast-preview2
              :value="item"
              v-if="item.data_type == 8"
            ></livebroadcast-preview2>

            <graphic :value="item" v-if="item.data_type == 9"></graphic>

            <richtext :value="item" v-else-if="item.data_type == 7"></richtext>

            <move
              v-if="
                (item.data_type == 6 &&
                  item.is_center == 1 &&
                  selected == item.id &&
                  index > 0) ||
                  (item.data_type != 6 &&
                    item.data_type != -2 &&
                    selected == item.id &&
                    index > 0)
              "
              :item="item"
              :index="index"
            ></move>
            <hidden
              v-if="
                (item.is_show == 2 &&
                  item.data_type == 6 &&
                  item.is_center == 1) ||
                  (item.data_type != 6 && item.is_show == 2)
              "
            ></hidden>
            <empty
              v-if="
                item.data_type > 0 &&
                  item.list &&
                  item.list.length == 0 &&
                  item.data_type != 6
              "
            ></empty>
            <div
              class="empty"
              v-if="
                item &&
                  item.data_type == 6 &&
                  item.list.length == 0 &&
                  item.is_center == 1
              "
            >
              <div>暂无预告数据</div>
              <div class="mt10">
                暂无直播预告，网校将不显示预告弹窗
              </div>
            </div>
          </div>
          <myFooter></myFooter>
        </div>
      </div>
      <!-- 右侧参数设置区域 -->
      <transition name="transformX">
        <div class="configure" v-if="selected">
          <!-- @selectChange="selectChange" -->
          <configure></configure>
        </div>
      </transition>
    </div>
    <!-- 轮播图选择网校内容 -->
    <networkschool-Select
      v-if="selectChangestatus"
      :dialogstatus="!!selectChangestatus"
      :title="selectChangestatus.title"
      :template="selectChangestatus.templete"
      :ids="selectChangestatus.ids"
      @selectChangenetworkschool="selectChangenetworkschool"
      @singleselectcontentval="singleselectcontentval"
    ></networkschool-Select>
    <!-- 轮播图选择分类弹窗 -->
    <selectcourse-type
      @typeselectval="typeselectval"
      v-if="selectcourseTypeStatus"
      :default_key="selectcourseTypeStatus.key"
      :dialogstatus="!!selectcourseTypeStatus"
      @close="kechengtype(false)"
    ></selectcourse-type>
    <!-- 多选课程分类 -->
    <selectcourse-type2
      @typeselectval="typeselectval"
      v-if="selectcourseTypeStatus2"
      :dialogstatus="selectcourseTypeStatus2"
      @close="kechengtype2(false)"
    ></selectcourse-type2>
    <!-- 图片选择弹窗 locationUpload本地上传触发 选中图片的回调 list  获取选中左侧列表的id group_id-->
    <addpicture
      v-if="addpicturestatus"
      :dialogstatus="addpicturestatus"
      @locationUpload="locationUpload"
      :type="1"
      :size="size"
      ref="addpicture"
      @group_id="group_id"
      @list="list"
      @close="changgePicture(false)"
    ></addpicture>
    <!-- 本地上传弹窗 -->
    <upload
      v-if="uploadStart"
      @uploadConfirmClose="uploadConfirmClose"
      @close="uploadConfirmClose"
      :typeselection="1"
      :title="'上传图片(多选)'"
      :uploadType="2"
      :list="group_id1"
    ></upload>

    <!-- 添加老师 -->
    <teacher-select
      v-if="teacherSelectstatus"
      :ids="teacherSelectstatus"
      :dialogstatus="!!teacherSelectstatus"
      @selectedteacher="selectedteacher"
      @close="teacherSelect(false)"
    ></teacher-select>
    <!--视频预览-->
    <previewVideo
      v-if="previewSrc"
      :src="previewSrc"
      @close="preViewVideo"
    ></previewVideo>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
//添加老师弹窗
import teacherSelect from '@cm/base/teacherSelect'
// 头部导航
import headNavigation from './components/headNavigation'
//本地上传弹窗
import upload from '@cm/base/UploadtextFile'
//图片组件
import addpicture from '@cm/base/addpicture'
//选择课程分类
import selectcourseType from '@cm/base/selectcourseType'
import selectcourseType2 from '@cm/base/selectcourseType2'
// 选择网校内容
import networkschoolSelect from '@cm/base/networktypeSelect/networkschoolSelect'
//右侧参数的配置
import configure from './components/configure'
//左侧模块添加
import modular from './components/modular'
//底部黑色背景
import schooldecorationBackground from '@/mixin/schooldecorationBackground'
//空状态
import empty from './centerComponents/empty'
//隐藏的样式
import hidden from './centerComponents/hidden'
//移动按钮组件
import move from './centerComponents/move'

// // 中间的模板
import graphic from './centerComponents/graphic'
import myheader from './centerComponents/Header'
import banner from './centerComponents/banner'
import openClass from './centerComponents/openClass'
import courseItem from './centerComponents/courseItem'
import electronic from './centerComponents/electronic'
import teacher from './centerComponents/teacher'
import myFooter from './centerComponents/footer'
import floatMenu from './centerComponents/floatMenu'
import previewVideo from '@cm/coursePop/previewVideo'
import livebroadcastPreview1 from './centerComponents/livebroadcastPreview1'
import livebroadcastPreview2 from './centerComponents/livebroadcastPreview2'
import richtext from './centerComponents/richtext'

export default {
  name: 'pcSchoolDecoration',

  mixins: [schooldecorationBackground],
  components: {
    modular,
    configure,
    networkschoolSelect,
    selectcourseType,
    selectcourseType2,
    addpicture,
    upload,
    headNavigation,
    teacherSelect,
    // 中间的模板组件
    myheader,
    banner,
    openClass,
    courseItem,
    empty,
    hidden,
    move,
    livebroadcastPreview2,
    livebroadcastPreview1,
    richtext,
    graphic,
    // work,
    electronic,
    teacher,
    myFooter,
    floatMenu,
    previewVideo,
  },

  computed: {
    ...mapState('pcSchoolDecration', [
      'pcscale',
      'changgePicturestatus',
      'kechengtypestatus',
      'kechengtypestatus2',
      'selecticonstatus',
      'selectChangestatus',
      'modules',
      'teacherSelectstatus',
      'selected',
      'group_id1',
      'uploadStart',
      'isSelectPicture',
    ]),
    // 页面id
    pc_t_home_id() {
      return this.$route.query.id
    },
    //添加图片弹窗控制
    addpicturestatus() {
      if (typeof this.changgePicturestatus == 'boolean') {
        return this.changgePicturestatus
      } else {
        return this.changgePicturestatus.status
      }
    },
    // 选择图片大小限制
    size() {
      if (typeof this.changgePicturestatus != 'boolean') {
        return this.changgePicturestatus.size
      } else {
        return undefined
      }
    },
    //课程分类弹窗
    selectcourseTypeStatus() {
      return this.kechengtypestatus
    },
    //多选分类弹窗
    selectcourseTypeStatus2() {
      return this.kechengtypestatus2
    },
  },

  data() {
    return {
      height: 0,
      width: 0,
      //false:展开 true：关闭
      isshow: true,
      previewSrc: '',

      transformStyle: '',
    }
  },

  created() {
    this.networkSchoolDecration(this.pc_t_home_id)
    window.addEventListener('resize', this.onPageResize)
    this.onPageResize()
  },

  watch: {
    modules: {
      handler() {
        this.$nextTick(() => {
          this.getSchoolClentWidth()
        })
      },
      deep: true,
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.onPageResize)
  },

  methods: {
    ...mapActions('pcSchoolDecration', ['networkSchoolDecration']),
    ...mapMutations('pcSchoolDecration', [
      'pcscaleChange',
      'selectedChange',
      'groupid1Change',
      'uploadStartChange',
      'isSelectPicturechange',
      'changgePicture',
      'selectChange',
      'kechengtype',
      'kechengtype2',
      'teacherSelect',
      'singleselectcontentvalchange',
      'typeselectlist',
      'selecticon',
      'selectedteacherChange',
      'changeScale',
    ]),

    // 监听页面resize
    onPageResize() {
      requestAnimationFrame(() => {
        const leftWidth = 120
        const rightWidth = 435
        const windowWidth = document.documentElement.clientWidth
        const maxWidth = 1310
        // 剩余空间
        const remainingSpace = windowWidth - leftWidth - rightWidth
        // 剩余充足的空间
        const isPlentySpace = remainingSpace >= maxWidth
        // 内容宽度
        const contentWidth = isPlentySpace ? maxWidth : remainingSpace
        // 居中
        const centerSpace = isPlentySpace
          ? (remainingSpace - contentWidth) / 2 + leftWidth
          : leftWidth
        // 如果剩余空间 大于 最大宽度
        const scale = contentWidth / maxWidth
        this.changeScale(scale)
        const transform = `translateX(${centerSpace}px) scale(${scale})`
        this.transformStyle = `transform: ${transform}`
        this.pcscaleChange(scale)
        this.getSchoolClentWidth()
        // console.log(
        //   windowWidth,
        //   leftWidth,
        //   rightWidth,
        //   isPlentySpace,
        //   contentWidth,
        //   centerSpace
        // )
      })
    },

    getSchoolClentWidth() {
      this.height = this.$refs.contBox.offsetHeight * this.pcscale
      this.width = this.$refs.contBox.clientWidth * this.pcscale
      this.$refs.net_shool_contain.style.width = `${this.width}px`
      this.$refs.net_shool_contain.style.height = `${this.height}px`
    },

    //获取老师选择弹窗回调
    selectedteacher(val) {
      this.selectedteacherChange(val)
    },
    //图标弹窗关闭
    closeicon() {
      this.selecticon(false)
    },
    //获取被选中课程分类的数据
    typeselectval(val) {
      this.typeselectlist(val)
    },
    //自定网校内容选择的数据
    singleselectcontentval(val) {
      this.singleselectcontentvalchange(val)
    },
    //控制指定网校内容的弹窗开关
    selectChangenetworkschool() {
      this.selectChange(false)
    },
    //选中图片的回调
    list(val) {
      this.isSelectPicturechange(val)
    },

    //被选中的样式
    isSelected(val) {
      this.selectedChange(val)
    },

    // 上传弹窗关闭
    uploadConfirmClose() {
      this.$refs.addpicture.renew()
      this.uploadStartChange(false)
    },

    //获取图片上传中的分组id
    group_id(val) {
      this.groupid1Change(val)
      // this.group_id1 = val
    },

    //本地上传弹窗控制
    locationUpload() {
      this.uploadStartChange(true)
    },

    // 轮播图片中指定网校内容的弹窗的弹出
    // selectChange(val) {
    //   this.status = val
    // },

    //左侧列表的开关
    closeopen() {
      this.isshow = !this.isshow
    },
    // 预览视频
    preViewVideo(item) {
      this.previewSrc =
        item && typeof item.video_url === 'string' ? item.video_url : ''
    },
  },
}
</script>

<style lang="scss" scoped>
#onlineSchoolDecoration {
  .empty {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    line-height: 19px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 40px 0;
  }
  .borderstyle {
    border: 2px solid #fff;
  }
  .borderstyle:hover {
    border: 2px dotted #0aa29b;
  }

  .borderstyle.moduleSelect {
    border: 2px solid #0aa29b;
  }
  .borderstyle.maskBorder {
    border: 2px solid transparent;
  }
  .main_shcool {
    padding: 58px 0 0;
    .phone-header {
      width: 375px;
      img {
        width: 100%;
        display: block;
      }
    }
    .left {
      overflow-y: auto;
      @extend %scroll;
      overflow-x: hidden;
      z-index: 7;
      transition: transform 0.35s ease;
      padding: 10px;
      box-sizing: border-box;
      width: 100px;
      height: calc(100vh - 58px);
      position: fixed;
      left: 0;
      bottom: 0;
      background: #ffffff;
      box-shadow: 4px 0px 10px 0px rgba(0, 0, 0, 0.12);
      transition: transform 0.35s;
      &.transFormHidden {
        transform: translateX(-100%);
      }

      .close {
        cursor: pointer;
        right: -30px;
        z-index: 10;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 32px;
        background: #1b9d97;
        border-radius: 0px 4px 4px 0px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          color: #fff;
        }
      }

      .open {
        position: absolute;
        top: 50%;
        right: -30px;
        transform: translateY(-50%);
        padding: 12px 8px;
        box-sizing: border-box;
        width: 30px;
        height: 100px;
        background: #1b9d97;
        border-radius: 0px 4px 4px 0px;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        line-height: 20px;
        cursor: pointer;
      }
    }
    .net_shool_contain {
      // overflow: hidden;
    }

    .net_school_show {
      width: 1310px;
      position: relative;
      // transform: scale(0.6);
      transform-origin: left top;
      min-height: calc(100vh - 116px);
      background-color: #fff;
      transition: transform 0.25s ease;
      // will-change: transform;
      .lr-mr {
        margin: 0 55px;
      }
      .module {
        position: relative;
        margin-bottom: 40px;
      }
    }
    .configure {
      z-index: 7;
      position: fixed;
      bottom: 0;
      right: 0;
      width: 415px;
      height: calc(100vh - 58px);
      background-color: #fff;
    }
  }
}
</style>
