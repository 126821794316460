<template>
  <header class="header">
    <div class="content">
      <div class="jg-logo">
        <div class="logo">
          <img v-if="value.logo" :src="value.logo" alt="" />
        </div>
        <div v-if="value.jg_name && value.name_status == 1" class="name">
          {{ value.jg_name }}
        </div>
      </div>
      <div class="c_nav">
        <a
          v-for="item in value.list"
          :key="item.id"
          class="item"
          href="javaScript:;"
          :title="item.link"
        >
          {{ item.name }}
        </a>
      </div>
      <div class="right">
        <div v-for="item in r_nav" :key="item.type">
          <div class="r_nav" v-if="!item.noOpen" :title="item.name">
            <img class="img" :src="item.url" alt="" />
          </div>
        </div>
        <div class="photo">
          <img class="img" :src="value.user_photo" alt="" />
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'courseIndexHeader',

  props: {
    value: [Object],
  },

  computed: {
    ...mapState(['userInfo']),

    r_nav() {
      return [
        {
          type: 1,
          name: '搜索',
          isShow: this.value.search_status == 1,
          url: 'https://f.dingdingkaike.com.cn/pcddkk/ico_sousuo.svg',
        },
        {
          type: 2,
          name: '会员中心',
          isShow: this.value.member_status == 1,
          url: 'https://f.dingdingkaike.com.cn/pcddkk/icon_vip18.svg',
        },
        {
          type: 3,
          name: '最近浏览',
          isShow: this.value.browse_status == 1,
          url: 'https://f.dingdingkaike.com.cn/pcddkk/icon_zjll.svg',
        },
        {
          type: 4,
          name: '我的课程',
          isShow: this.value.course_status == 1,
          url: 'https://f.dingdingkaike.com.cn/pcddkk/icon_wdkc.svg',
        },
      ]
    },
  },

  methods: {},
}
</script>
<style lang="scss" scoped>
.header {
  height: 68px;
  .content {
    height: inherit;
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
    .jg-logo {
      display: flex;
      align-items: center;
      margin-right: 45px;
      .logo {
        margin-right: 9px;
        max-width: 140px;
        height: 40px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          object-fit: contain;
        }
      }
      .name {
        font-size: 13px;
        font-weight: bold;
        color: #333333;
        margin-left: 10px;
      }
    }
    .c_nav {
      font-size: 0;
      .item {
        padding: 0 15px;
        font-size: 14px;
        color: #333333;
        line-height: 68px;
        text-align: center;
        display: inline-block;
        text-decoration: none;
      }
    }

    .right {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      padding-right: inherit;
      ::v-deep .search {
        margin-left: 20px;
        margin-right: 20px;
        .el-input {
          width: 190px;
          input {
            background: #ececec;
          }
        }
      }
      .r_nav {
        padding: 13px 17px;
        .img {
          height: 16px;
          margin: 0 auto;
        }
        .text {
          font-size: 12px;
          color: #333333;
          line-height: 24px;
        }
      }
      .photo {
        margin-left: 7px;
        cursor: pointer;
        .img {
          width: 38px;
          height: 38px;
        }
      }
    }
  }
}
</style>
