<template>
  <div id="courseSetting">
    <div class="box">
      <div class="module-title">直播预告</div>
      <!-- 模块标题 -->
      <div class="title">模块标题</div>
      <el-input
        type="text"
        placeholder="请输入模块标题"
        v-model="optionlist.name"
        maxlength="15"
        show-word-limit
        class="mb10"
      ></el-input>

      <!-- <div class="show-type mt25">
        <div class="title">
          展示位置
        </div> 
          <el-checkbox
            label="右侧悬浮"
            true-label="1"
            false-label="2"
            v-model="optionlist.is_hover"
          ></el-checkbox>
          <el-checkbox
            label="页面居中"
            true-label="1"
            false-label="2"
            v-model="optionlist.is_center"
          ></el-checkbox> 
      </div> -->

      <div class="show-type mt25">
        <div class="title">
          预告内容
        </div>
        <el-checkbox
          label="公开课"
          true-label="1"
          false-label="2"
          v-model="optionlist.live_status"
        ></el-checkbox>
        <el-checkbox
          label="直播课"
          true-label="1"
          false-label="2"
          v-model="optionlist.course_status"
        ></el-checkbox>
        <el-checkbox
          label="小班课"
          true-label="1"
          false-label="2"
          v-model="optionlist.small_status"
        ></el-checkbox>
      </div>

      <div class="show-type mt25">
        <div class="title">
          预告时间范围
          <helpIcon
            style="margin-left: 5px"
            content="时间范围指：预告内容只显示这个范围内开播的直播，比如一天内，预告只显示当天的内容，大于当天的直播不会出现在预告里"
          ></helpIcon>
        </div>
        <el-radio-group v-model="optionlist.time_type" class="radio1 ">
          <el-radio label="1">一天内</el-radio>
          <el-radio label="2">7天内</el-radio>
          <el-radio label="3">30天内</el-radio>
          <el-radio label="4">自定义</el-radio>
        </el-radio-group>
        <template v-if="optionlist.time_type == 4">
          <div class="sys">
            <el-input-number
              v-model="optionlist.days"
              :min="1"
              :max="120"
              :step="1"
              size="small"
            ></el-input-number>
            <span class="fs12 ml10" style="color: #999999;">
              天，最小1天，最高120天
            </span>
          </div>
        </template>
      </div>

      <div class="show-type mt25" v-if="optionlist.data_type == 6">
        <div class="title flex-center">
          课程类型标识
          <el-popover placement="top-start" trigger="hover">
            <img src="@ass/img/1.4.9/img_kclxbsslt.png" alt="" />
            <div slot="reference" class="help"></div>
          </el-popover>
        </div>
        <div class="switch1">
          <el-switch
            v-model="optionlist.course_type_hide"
            active-value="1"
            inactive-value="2"
          ></el-switch>
          <span class="active_text" v-if="optionlist.course_type_hide == 1">
            已开启
          </span>
          <span class="active_text" v-else>
            已关闭
          </span>
        </div>
      </div>
    </div>
    <bottombtn @submit="submit" @cancel="selectedChange('')"></bottombtn>
  </div>
</template>

<script>
import selectdata from './selectdata'
import { mapMutations, mapState } from 'vuex'
import bottombtn from './components/bottomBtns'
// import movebtn from './components/moveBtns'

export default {
  name: 'workSetting',

  components: {
    bottombtn,
    // movebtn,
  },

  mixins: [selectdata],

  data() {
    return {}
  },

  created() {
    this.submit('noprompt')
  },

  computed: {
    ...mapState('pcSchoolDecration', ['selectdata']),
  },

  methods: {
    ...mapMutations('pcSchoolDecration', [
      'selectChange',
      'selectdataChange',
      'selectedChange',
      'toEnd',
    ]),

    getCourseData({ val }) {
      return new Promise(resolve => {
        this.$http({
          url: '/pcHome/getNotice',
          data: val,
          callback: ({ data }) => {
            resolve(data)
          },
        })
      })
    },

    getData() {
      const val = _.merge({}, this.optionlist)
      return this.getCourseData({
        val,
      }).then(data => {
        val.list = data
        this.selectdataChange(val)
        // if (this.optionlist.is_center == 1 && this.selectdata.is_center != 1) {
        //   this.toEnd(this.optionlist.id)
        //   this.$nextTick(() => {
        //     window.scrollTo({
        //       top: document.body.scrollHeight,
        //       behavior: 'smooth', //可注释查看-快慢
        //     })
        //   })
        // } else if (
        //   this.optionlist.is_hover == 1 &&
        //   this.selectdata.is_hover != 1
        // ) {
        //   window.scrollBy(0, -10)
        // }
      })
    },

    submit(val) {
      this.optionlist.name = this.optionlist.name.trim()
      this.noprompt = val
      this.getData().then(() => {
        if (val != 'noprompt') {
          this.successPrompt()
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import './courseStting.scss';
.help {
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-left: 6px;
  background: url('~@ass/img/1.2.9/ico_wh@2x.png');
  background-size: 100%;
  &.icon2 {
    width: 8px;
    height: 13px;
    background: url('~@ass/img/1.2.9/ico_xljt.png');
    background-size: 100%;
  }
}
.active_text {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-left: 10px;
}
::v-deep .show-type {
  .el-radio {
    margin-right: 30px !important;
  }
}
</style>
