/*
 * @Author: cyy
 * @version:
 * @Date: 2022-05-11 16:06:26
 * @LastEditTime: 2022-06-01 16:04:26
 * @LastEditors: cyy
 * @Description:
 * @FilePath: \teacher.dingdingkaike.com\src\views\NetSchollAdmin\pcSchoolDecoration\editComponents\selectdata.js
 */
/**
 * 网校装修的公共js
 */

import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      optionlist: {},
    }
  },

  computed: {
    ...mapState('pcSchoolDecration', ['selectdata']),
  },

  watch: {
    ...mapMutations('pcSchoolDecration', ['selectedChange']),

    selectdata: {
      handler(value) {
        if (this.optionlist.id && value.id === this.optionlist.id) {
          this.optionlist = _.merge(
            {},
            _.assign(
              {},
              value,
              this.optionlist,
              // 如果is_show 相等 说明改的是其他数据，新数据就要覆盖旧数据
              value.is_show == this.optionlist.is_show
                ? value
                : {
                    is_show: value.is_show,
                  }
            )
          )
        } else {
          this.optionlist = _.merge({}, value)
          this.coptylist = _.merge({}, value)
          if (this.init) {
            this.init()
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    success() {
      this.saveDataSuccess()
      this.successPrompt()
    },

    saveDataSuccess() {
      this.selectdataChange(this.optionlist)
    },

    //上移 下移
    upDownMove(index, newIndex, name = 'list') {
      let optionlist = this.optionlist[name]
      const oldData = optionlist[newIndex]
      optionlist.splice(newIndex, 1, optionlist[index])
      optionlist.splice(index, 1, oldData)
    },

    //删除
    removeItem(val, name = 'list') {
      this.optionlist[name].splice(val, 1)
    },

    successPrompt() {
      this.$root.prompt({
        msg: '编辑成功，请点击右上角按钮发布到网校！',
        type: 'success',
      })
      this.selectedChange(this.optionlist.id)
    },
  },
}
