<template>
  <div class="bottom-btns">
    <el-button class="mr20 btn" size="medium" @click="cancel">
      取消
    </el-button>
    <el-button class="btn" size="medium" type="primary" @click="submit">
      保存
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'bottom-btns',

  methods: {
    submit() {
      this.$emit('submit')
    },

    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="scss" scoped>
.bottom-btns {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 76px;
  font-size: 0;
  line-height: 86px;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px -1px 0px 0px #00000014;
  .btn {
    width: 100px;
    height: 36px;
    border-radius: 4px;
  }
}
</style>
