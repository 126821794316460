<template>
  <div id="courseSetting">
    <div class="box">
      <!-- 模块标题 -->
      <div class="module-title">课程</div>
      <div class="title">模块标题</div>
      <el-input
        type="text"
        placeholder="请输入模块标题"
        v-model="optionlist.name"
        maxlength="15"
        show-word-limit
        class="mb30"
      ></el-input>

      <!-- 课程信息展示开关 -->
      <div class="title">其他信息展示</div>
      <div class="switchs mb10">
        <el-switch
          v-model="optionlist.is_show_more"
          active-value="1"
          inactive-value="2"
        >
          >
        </el-switch>
        <span class="kaiguang">查看全部</span>
      </div>

      <!-- 展示课程 -->
      <div class="title">展示课程</div>
      <el-radio-group
        v-model="optionlist.show_data_type"
        @change="radioChage"
        class="mb10"
      >
        <el-radio label="1">全部课程</el-radio>
        <el-radio label="2">课程分类</el-radio>
        <el-radio label="3">自定义</el-radio>
      </el-radio-group>
      <!-- <div class="redtip" v-if="optionlist.show_data_type == 1">
        提示：当前网校无课程数据
      </div> -->
      <div class="custom" v-if="optionlist.show_data_type == 2">
        <div class="flex-center">
          <el-button style="width: 140px" @click="selectCategory">
            + 选择分类
          </el-button>
          <span class="ml20 hedselect" v-if="optionlist.set_id > 0">
            已选：{{ optionlist.set_name }}
          </span>
        </div>
      </div>
      <!-- 首页展示数量（学员点击更多可显示全部） -->
      <template>
        <div
          class="title"
          :style="optionlist.show_data_type != 1 ? 'margin-top:20px' : ''"
        >
          首页展示数量（学员点击更多可显示全部）
        </div>
        <div class="flex-center input_number">
          <el-input-number
            v-model="optionlist.show_num"
            :min="1"
            :max="20"
            :step="1"
            step-strictly
          ></el-input-number>
          <span class="tip">数量最高可设置20个</span>
        </div>
      </template>

      <!-- 自定义选择课程 -->
      <div class="custom" v-if="optionlist.show_data_type == 3">
        <div class="flex-center">
          <el-button style="width: 140px" @click="addCourse">
            + 选择课程
          </el-button>
          <span class="ml20 hedselect">已选 {{ optionlist.list.length }}</span>
        </div>
        <div
          class="course_contian"
          v-for="(i, index) in optionlist.list"
          :key="i.course_id"
        >
          <div class="tu">
            <img :src="i.photo" alt="" />
          </div>
          <div class="right">
            <div class="name">
              {{ i.name }}
            </div>
            <div class="bottom">
              <div class="coursetype">
                {{ i.course_type | courseTypeText }} |
                {{
                  i.price_type == 1
                    ? `￥${i.price}`
                    : i.price_type == 2
                    ? '免费'
                    : '密码获取'
                }}
              </div>
              <movebtn
                :index="index"
                :length="optionlist.list.length"
                @upDownMove="upDownMove"
                @remove="removeItem"
              ></movebtn>
            </div>
          </div>
        </div>
      </div>

      <!-- 排序规则 -->
      <div v-if="optionlist.show_data_type != 3" class="mt20">
        <div class="title">排序规则</div>
        <el-radio-group v-model="optionlist.sort_way" class="radio2">
          <el-radio label="1">创建时间从新到旧</el-radio>
          <el-radio label="2">创建时间从旧到新</el-radio>
          <el-radio label="3">学习人数从多到少</el-radio>
          <el-radio label="4">学习人数从少到多</el-radio>
          <el-radio label="5">访问人数从多到少</el-radio>
          <el-radio label="6">访问人数从少到多</el-radio>
          <el-radio label="7" v-if="optionlist.show_data_type == 2">
            跟随管理后台的排序
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <bottombtn @submit="submit" @cancel="selectedChange('')"></bottombtn>
  </div>
</template>

<script>
import selectdata from './selectdata'
import { mapMutations, mapState } from 'vuex'
import bottombtn from './components/bottomBtns'
import movebtn from './components/moveBtns'

export default {
  name: 'courseSetting',

  mixins: [selectdata],

  components: {
    bottombtn,
    movebtn,
  },

  watch: {
    // 改变指定网校内容
    singleselectcontentval(val) {
      this.optionlist.list.push(...val)
    },

    // 改变课程分类的数据
    typeselectval(val) {
      this.optionlist = _.merge({}, this.optionlist, {
        set_id: val.set_id,
        set_name: val.set_name,
      })
    },

    'optionlist.show_data_type'(val) {
      let set_id = ''
      let list = []
      // 如果切换到初始类型时，数据进行重置
      if (val == this.selectdata.show_data_type) {
        list = _.merge([], this.selectdata.list)
        set_id = this.selectdata.set_id || ''
      }
      this.optionlist = _.assign({}, this.optionlist, {
        list,
        set_id,
      })
    },
  },

  computed: {
    ...mapState('pcSchoolDecration', [
      'singleselectcontentval',
      'typeselectval',
      'selectdata',
    ]),
  },

  methods: {
    ...mapMutations('pcSchoolDecration', [
      'kechengtype',
      'selectChange',
      'selectdataChange',
      'selectedChange',
    ]),

    radioChage() {
      this.optionlist.sort_way = '1'
    },

    getCourseData(option) {
      return new Promise(resolve => {
        this.$http({
          isMultipleSelf: 'all',
          url: '/HomeTemplate/courseList',
          data: option,
          callback: ({ data }) => {
            resolve(data)
          },
        })
      })
    },

    // 选择分类
    selectCategory() {
      this.kechengtype({ key: this.optionlist.set_id })
    },

    // 选择课程
    addCourse() {
      this.selectChange({
        templete: 2,
        title: '选择课程',
        ids: this.optionlist.list.map(item => item.course_id),
      })
    },

    // 移动
    move(newIndex, oldIndex) {
      const item = this.optionlist.list[newIndex]
      this.optionlist.list.splice(newIndex, 1, this.optionlist.list[oldIndex])
      this.optionlist.list.splice(oldIndex, 1, item)
    },

    // 删除
    remove(index) {
      this.optionlist.list.splice(index, 1)
    },

    init() {
      // 空数据才去获取
      if (
        this.optionlist.list.length > 0 ||
        this.optionlist.show_data_type == 3
      )
        return
      this.getData()
    },

    getData() {
      const val = _.merge({}, this.optionlist)
      return this.getCourseData({
        type: val.show_data_type,
        set_id: val.set_id,
        pagesize: val.show_num,
        sort_way: val.sort_way,
      }).then(data => {
        val.list = data
        this.selectdataChange(val)
      })
    },

    submit() {
      this.optionlist.name = this.optionlist.name.trim()
      if (this.optionlist.show_data_type == 3) {
        this.success()
      } else {
        this.getData().then(() => {
          this.successPrompt()
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import './courseStting.scss';
</style>
