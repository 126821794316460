<template>
  <div class="my-header">
    <div class="title">悬浮菜单</div>
    <div class="setting mb20">
      <el-switch
        v-model="optionlist.status"
        active-value="1"
        inactive-value="2"
      ></el-switch>
      <div class="font2">已{{ optionlist.status == 1 ? '开启' : '关闭' }}</div>
    </div>
    <template v-if="optionlist.status == 1">
      <div class="main" ref="list">
        <div v-for="(item, index) in optionlist.list" :key="item.id">
          <template v-if="item.default_type != 4">
            <div class="contain">
              <div class="left_teacher">
                <img class="icon" :src="item.icon_url" alt="" />
                <div class="name">{{ item.name }}</div>
              </div>
              <movebtn
                :index="index"
                :length="optionlist.list.length"
                @upDownMove="upDownMove"
                @remove="removeItem"
                popconfirm="是否确定移除该菜单"
              ></movebtn>
            </div>
          </template>
          <template v-else>
            <div class="contain2">
              <movebtn
                :index="index"
                :length="optionlist.list.length"
                @upDownMove="upDownMove"
                @remove="removeItem"
                style="text-align: right"
                popconfirm="是否确定移除该菜单"
              ></movebtn>
              <div class="input1">
                <div
                  v-if="!item.icon_url"
                  class="t1 custom-t1"
                  @click="custom(item.id, 'icon_url')"
                >
                  未选
                  <br />
                  图标
                </div>

                <div
                  v-else
                  @click="custom(item.id, 'icon_url')"
                  class="t1 custom-t1 custom-t1-img"
                >
                  <img
                    style="height: 100%; width: 100%; object-fit: contain"
                    :src="item.icon_url"
                    alt=""
                  />
                </div>

                <el-input
                  class="t2"
                  v-model.trim="item.name"
                  placeholder="请输入名称"
                  maxlength="5"
                  show-word-limit
                ></el-input>
              </div>
              <el-radio-group v-model="item.type" style="margin: 20px 0">
                <el-radio label="1">跳转链接</el-radio>
                <el-radio label="2">弹二维码</el-radio>
              </el-radio-group>
              <el-input
                v-if="item.type == 1"
                class="t2"
                v-model.trim="item.link"
                placeholder="请输入跳转链接 https://"
                maxlength="300"
              ></el-input>
              <div class="erwema" v-else>
                <div class="left">
                  <img
                    :src="item.qrcode"
                    alt=""
                    style="object-fit: contain"
                    v-if="item.qrcode"
                  />
                  <div class="nourl" v-else><span>未添加图片</span></div>
                </div>
                <div class="right">
                  <div class="text">
                    建议尺寸500*500px，JPG、PNG格式，小于2M
                  </div>
                  <el-button @click="custom(item.id, 'qrcode')">
                    选择图片
                  </el-button>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div v-if="optionlist.list.length == max" class="add-btn disable">
        + 添加菜单 ({{ optionlist.list.length }}/{{ max }})
      </div>

      <el-popover v-else placement="bottom" width="335" trigger="click">
        <div class="add-btn" slot="reference">
          + 添加菜单 ({{ optionlist.list.length }}/{{ max }})
        </div>
        <div
          v-for="item in addList"
          :key="item.default_type"
          class="pcschool-add-list"
        >
          <div class="item" v-if="!item.noOpen">
            <template v-if="!item.isHas && optionlist.list.length < max">
              <div class="t1 green" @click="add(item)">
                {{ item.name || '添加自定义导航' }}
              </div>
              <div class="t2 green" v-if="item.name" @click="add(item)">
                可添加
              </div>
            </template>
            <template v-else>
              <div class="t1">{{ item.name || '添加自定义导航' }}</div>
              <div class="t2 gray" v-if="item.name">已添加</div>
            </template>
          </div>
        </div>
      </el-popover>
    </template>
    <el-button id="popo-btn" ref="popo" />
    <bottombtn @submit="submit" @cancel="cancel"></bottombtn>
  </div>
</template>

<script>
import selectdata from './selectdata'
import { mapMutations, mapState } from 'vuex'
import bottombtn from './components/bottomBtns'
import movebtn from './components/moveBtns'

export default {
  name: 'my-header',

  components: {
    bottombtn,
    movebtn,
  },

  mixins: [selectdata],

  data() {
    return {
      max: 6,
      selecticonid: {},
      optionlist: _.merge({}, this.selectdata),
      addDefaultList: [
        {
          default_type: '1',
          name: '会员专区',
          icon_url: 'https://f.dingdingkaike.com.cn/pcddkk/icon_vip18.svg',
          noOpen: this.$store.state.userInfo.is_course_member == 2,
        },
        {
          default_type: '2',
          name: '手机选课',
          icon_url: 'https://f.dingdingkaike.com.cn/pcddkk/icon_sjxk2.svg',
        },
        {
          default_type: '3',
          name: '返回顶部',
          icon_url: 'https://f.dingdingkaike.com.cn/pcddkk/icon_fhdb2.svg',
        },
        {
          default_type: '4',
          name: '',
          sort_num: '',
          icon_url: '',
          link: '',
          type: '1',
        },
      ],
    }
  },

  computed: {
    ...mapState('pcSchoolDecration', ['selectdata', 'isSelectPicture']),

    addList() {
      return this.addDefaultList.map(item =>
        Object.assign({}, item, {
          isHas:
            item.default_type != 4 &&
            this.optionlist.list.length > 0 &&
            this.optionlist.list.some(v => v.default_type == item.default_type),
          id: Math.random(),
        })
      )
    },
  },

  watch: {
    // 改变当前选中修改图像的值
    isSelectPicture(newVal) {
      const index = this.optionlist.list.findIndex(
        item => item.id == this.selecticonid.id
      )
      const val = _.assign({}, this.optionlist.list[index], {
        [this.selecticonid.property]: newVal.url,
      })
      this.optionlist.list.splice(index, 1, val)
    },
  },

  methods: {
    ...mapMutations('pcSchoolDecration', [
      'changgePicture',
      'selectdataChange',
      'selectedChange',
    ]),

    submit() {
      if (this.optionlist.status == 1) {
        let value
        if (
          (value = this.optionlist.list.find(
            item =>
              item.default_type == 4 &&
              (!item.icon_url ||
                !item.name ||
                (item.type == 1 && !item.link) ||
                (item.type == 2 && !item.qrcode))
          ))
        ) {
          if (!value.name) {
            this.$root.prompt('请先输入菜单名称！')
            return
          }
          if (!value.icon_url) {
            this.$root.prompt('请先选择图标！')
            return
          }
          if (value.type == 1 && !value.link) {
            this.$root.prompt('请先输入跳转链接！')
            return
          }
          if (value.type == 2 && !value.qrcode) {
            this.$root.prompt('请先添加图片！')
            return
          }
        }
      } else {
        this.optionlist = _.assign({}, this.selectdata, {
          status: this.optionlist.status,
        })
      }
      this.success()
    },

    //自定义图标
    custom(val, property) {
      this.selecticonid = { id: val, property }
      if (property == 'qrcode') {
        this.changgePicture({ status: true, size: 2 })
      } else {
        this.changgePicture(true)
      }
    },

    cancel() {
      this.selectedChange('')
    },

    add(item) {
      this.optionlist.list.push(Object.assign({}, item))
      this.$refs.popo.$el.click()
      this.$nextTick(() => {
        this.$refs.list.scrollTop = this.$refs.list.scrollHeight
      })
    },
  },
}
</script>

<style lang="scss">
.pcschool-add-list {
  .item {
    display: flex;
    padding: 8px 20px;
    align-items: center;
    .t1 {
      flex: 1;
      font-size: 14px;
      color: #666666;
      &.green {
        color: #0aa29b;
        cursor: pointer;
      }
    }
    .t2 {
      font-size: 14px;
      cursor: pointer;
      &.green {
        color: #0aa29b;
      }
      &.gray {
        color: #999999;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
#popo-btn {
  width: 0;
  height: 0;
  border: none;
  padding: 0;
  margin: 0;
}
// ::-webkit-scrollbar {
//   width: 0px;
//   height: 0px;
//   background-color: #fff;
// }
.my-header {
  height: 100%;
  .main {
    max-height: calc(100% - 245px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: #fff;
    }
  }
  .title {
    font-size: 14px;
    color: #333333;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .contain {
    padding: 10px;
    box-sizing: border-box;
    height: 46px;
    background: #f5f5f5;
    border-radius: 2px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left_teacher {
      display: flex;
      align-items: center;
      .icon {
        width: 20px;
        height: 20px;
        margin-right: 20px;
        object-fit: contain;
      }
      .name {
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      margin-right: 20px;
    }
  }
  .contain2 {
    padding: 0 10px 10px;
    background: #f5f5f5;
    border-radius: 2px;
    margin-bottom: 10px;
    .input1 {
      display: flex;
      margin-top: 10px;
      align-items: center;
      .t1 {
        font-size: 14px;
        color: #333333;
        white-space: nowrap;
        margin-right: 10px;
      }
      .custom-t1 {
        width: 46px;
        height: 46px;
        background: #e7e7e7;
        border-radius: 4px;
        font-size: 12px;
        color: #666666;
        text-align: center;
        padding-top: 7px;
        line-height: 16px;
        cursor: pointer;
        box-sizing: border-box;
        &.custom-t1-img {
          padding: 0;
          object-fit: contain;
          background: none;
          background-size: 30px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
  .add-btn {
    height: 46px;
    margin-top: 10px;
    line-height: 46px;
    background: #f5f5f5;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    color: #0aa29b;
    &.disable {
      color: #999999;
    }
  }
}

.erwema {
  display: flex;
  .left {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    .nourl {
      font-size: 12px;
      color: #999999;
      line-height: 15px;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      background-color: #fff;
      justify-content: center;
    }
    img {
      height: 100%;
      width: 100%;
    }
  }
  .right {
    .text {
      font-size: 12px;
      color: #666666;
      margin-bottom: 26px;
    }
  }
}
.setting {
  display: flex;

  .font2 {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    margin-left: 12px;
  }
}
</style>
