<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-05-30 19:03:06
 * @LastEditTime: 2022-07-06 18:12:44
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\NetSchollAdmin\pcSchoolDecoration\centerComponents\livebroadcastPreview1.vue
-->
<template>
  <div
    class="livebroadcastPreview1"
    :style="value.is_show == 2 ? 'border:0' : ''"
  >
    <div class="main">
      <div class="title">
        <div class="flex-center">
          <img src="@ass/img/1.4.9/icon_pczx_zbyg.svg" alt="" />
          <div class="name">
            {{ value.name }}({{ value.list && value.list.length }})
          </div>
        </div>
        <i class="el-icon-close cp"></i>
      </div>
      <div class="main_list" v-if="value.list.length > 0">
        <div class="item" v-for="item in value.list" :key="item.key">
          <div
            class="content"
            :style="item.type != 3 ? 'border-bottom: 1px solid #E8E8E8;' : ''"
          >
            <div class="tu">
              <img :src="item.photo" alt="" />
            </div>
            <div class="right">
              <div class="top">
                <div
                  v-if="value.course_type_hide == 1"
                  class="type"
                  :style="
                    item.type == 1
                      ? 'background: #3d80ef'
                      : item.type == 2
                      ? 'background: #997EE1'
                      : 'background: #FE7B4B'
                  "
                >
                  {{ item.type | gettype }}
                </div>
                <div class="name" :title="item.title">{{ item.title }}</div>
              </div>
              <div class="bottom">
                <div class="time">{{ item.star_time }}开播</div>
                <div class="btn">
                  <template
                    v-if="
                      item.is_taday_tomorrow == 1 || item.is_taday_tomorrow == 2
                    "
                  >
                    <el-button
                      type="danger"
                      size="small"
                      style="width:80px"
                      v-if="item.islive == 1"
                    >
                      正在直播
                    </el-button>
                    <el-button
                      type="primary"
                      size="small"
                      style="width:80px"
                      v-else
                    >
                      即将开播
                    </el-button>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="coursename" v-if="item.type != 3">{{ item.name }}</div>
        </div>
      </div>
      <div class="empty" v-else>
        <div>暂无预告数据</div>
        <div class="mt10">
          暂无直播预告，网校将不显示预告弹窗
        </div>
      </div>
      <hidden v-if="value.is_show == 2"></hidden>
      <move
        v-if="selected == value.id"
        :item="value"
        :index="index"
        :noMove="true"
      ></move>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
//移动按钮组件
import move from './move'
//隐藏的样式
import hidden from './hidden'
export default {
  name: 'livebroadcastPreview1',

  props: {
    value: Object,
    index: [String, Number],
  },

  components: {
    hidden,
    move,
  },

  mounted() {
    window.addEventListener('scroll', this.scrollfn)
  },

  computed: {
    ...mapState('pcSchoolDecration', ['selected', 'scale']),
  },

  filters: {
    gettype(val) {
      let text = ''
      switch (Number(val)) {
        case 3:
          text = '公开课'
          break
        case 1:
          text = '直播课'
          break
        case 2:
          text = '小班课'
          break
      }
      return text
    },
  },

  destroyed() { 
    window.removeEventListener('scroll', this.scrollfn)
  },

  methods: {
    scrollfn() {
      requestAnimationFrame(() => {
        if (document.querySelector('.livebroadcastPreview1')) { 
          document.querySelector(
            '.livebroadcastPreview1'
          ).style.transform = `translateY(${window.scrollY / this.scale}px)`
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.livebroadcastPreview1 {
  flex-direction: column;
  box-sizing: border-box;
  width: 390px;
  min-height: 180px;
  max-height: 384px;
  z-index: 100;
  position: fixed;
  background-color: #fff;
  right: 90px;
  top: 155px;
  box-shadow: -5px 2px 12px 0px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  .main {
    display: flex;
    flex-direction: column;
    min-height: 176px;
    max-height: 380px;
  }
  .title {
    padding: 9px 14px;
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;

    .name {
      font-size: 12px;
      font-weight: bold;
      color: #333333;
      margin-left: 6px;
    }
  }
  .main_list {
    margin-bottom: 10px;
    flex: 1;
    overflow-y: auto;
    @extend %scroll;
    .item {
      background: #f5f5f5;
      margin: 0 14px 10px;
      padding: 10px;
      .content {
        padding-bottom: 10px;
        display: flex;
        .tu {
          width: 120px;
          height: 68px;
          background: #bbbbbb;
          border-radius: 3px;
          margin-right: 10px;
          flex-shrink: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .right {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .top {
            display: flex;
            align-items: center;
            .type {
              font-size: 12px;
              color: #ffffff;
              line-height: 19px;
              padding-left: 1px;
              box-sizing: border-box;
              width: 40px;
              height: 16px;
              border-radius: 2px;
              margin-right: 4px;
              flex-shrink: 0;
            }
            .name {
              flex: 1;
              overflow: hidden;
              width: 1px;
              text-overflow: ellipsis;
              word-break: break-all;
              white-space: nowrap;
              font-size: 14px;
              font-weight: bold;
              color: #333333;
            }
          }
          .bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .time {
              font-size: 12px;
              color: #666666;
            }
            .btn {
            }
          }
        }
      }
      .coursename {
        font-size: 12px;
        color: #666666;
        margin-top: 14px;
      }
    }
  }
  .empty {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    line-height: 19px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 40px;
  }
}
</style>
